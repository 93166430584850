import React, { useEffect, useState } from "react";
import FontIcon from "react-md/lib/FontIcons";
import "../styles/expandableTableAssignedServices.css";
import EventService from "../../../../../service/event/EventService";
import moment from "moment";
import NoRecordsIcon from "../../../../../assets/icons/no-records.png";
import { Button, SelectField } from "react-md";
import filterApplied from "../../../../../assets/icons/filter-applied-icon.svg";
import funnelIcon from "../../../../../assets/icons/funnel.svg";
import {
  TextField,
  MenuItem,
  Menu,
  IconButton,
  Radio,
} from "@material-ui/core";

const ExpandableTableAssignedServices = (props) => {
  const [expandedRowId, setExpandedRowId] = useState(null); // state to track expanded row
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuFor, setMenuFor] = useState({});
  const [tableData, setTableData] = useState(props?.data?.assignedServices);
  const [filterObj, setFilterObj] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("props recvd in expandabel table: ", props);
  }, []);

  useEffect(() => {
    setTableData(props?.data?.assignedServices);
    console.log("props recvd in expandabel table: ", props);
  }, [props]);

  useEffect(() => {
    // props.fetchAssignedServicesByFilter(filterObj)
    console.log("props recvd in expandabel table: ", props);
  }, [filterObj]);

  const STATUS_MAP = {
    Active: 1,
    Inactive: 2,
    Upcoming: 3,
    Expired: 4,
  };

  const handleRowClick = (id) => {
    setExpandedRowId(expandedRowId === id ? null : id); // toggle expansion
  };
  const handleMoreClick = (event, service) => {
    setAnchorEl(event.currentTarget);
    setMenuFor({ planData: props?.data?.planData, service: service });
  };

  return (
    <div className="table-container-assigned-plans">
      <table className="expandable-table">
        <thead>
          <tr>
            <th>Service ID</th>
            <th>Service type</th>
            <th>Service code</th>
            <th>Service mode</th>
            <th>MRP</th>
            <th>Discounted price</th>
            <th>Advance rule</th>
            <th>
              Status
              <img
                className="filter-icon-assigned-plans"
                src={filterObj?.status ? filterApplied : funnelIcon}
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(1);
                }}
              />
              {openFilter === 1 && (
                <div className="filter-div-assigned-plans-table">
                  <div className="can-be-booked-for-filter">
                    {Object.entries(STATUS_MAP).map(([key, val]) => (
                      <div>
                        <Radio
                          label={key}
                          checked={filterObj?.status == val}
                          onClick={() => {
                            setOpenBackdrop(false);
                            setOpenFilter(-1);
                            setFilterObj((prev) => ({ ...prev, status: val }));
                          }}
                        />
                        <label className="label-radio-btn">{key}</label>
                      </div>
                    ))}
                    <Button
                      className="clear-navigo-booked-for"
                      onClick={() => {
                        setFilterObj((prev) => ({ ...prev, status: null }));
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              )}
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="animated-box">
          {Boolean(tableData?.length == null || tableData?.length <= 0) && (
            <tr>
              <td colSpan={10}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 40,
                  }}
                >
                  <div
                    style={{ textAlign: "center", fontFamily: "Montserrat" }}
                  >
                    No records to show
                  </div>
                  <div style={{ height: "16px" }}></div>
                  <img src={NoRecordsIcon} width={50} height={62} />
                </div>
              </td>
            </tr>
          )}

          {tableData && tableData.length > 0 && (
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td
                colSpan={10}
                align="right"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Button
                  onClick={() => {
                    props.goToPrevPage();
                  }}
                  disabled={props?.pageNum <= 1 || loading}
                >
                  <FontIcon className="navigo-count-icon">arrow_left</FontIcon>
                </Button>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  Showing&nbsp;
                  <span
                    style={{
                      color: "#ef7c01",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {(props?.pageNum - 1) * props?.pageSize + 1}&nbsp;
                  </span>
                  <span
                    style={{
                      color: "#ef7c01",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    - &nbsp;
                    {props?.totalCount > props?.pageNum * props?.pageSize
                      ? props?.pageNum * props?.pageSize
                      : props?.totalCount}
                  </span>
                  <span>&nbsp;&nbsp;of&nbsp;</span>
                  <span>{` ${props?.totalCount}`}</span>
                </div>
                <Button
                  onClick={() => {
                    props.goToNextPage();
                  }}
                  disabled={
                    loading ||
                    props?.pageNum * props?.pageSize > props?.totalCount ||
                    (props?.totalCount > props?.pageNum * props?.pageSize
                      ? props?.pageNum * props?.pageSize
                      : props?.totalCount) == props?.totalCount
                  }
                >
                  <FontIcon>arrow_right</FontIcon>
                </Button>
              </td>
              <td />
            </tr>
          )}

          {tableData.map((row, index) => (
            <React.Fragment key={index}>
              <tr
                // onClick={() => handleRowClick(row?.id)}
                className={`${index > 0 ? "table-row-border" : ""}`}
              >
                <td>{row?.id}</td>
                <td>{row?.serviceTypeName}</td>
                <td>{row?.name}</td>
                <td>{row?.serviceModeDetails?.[0]?.modeName}</td>
                <td>{row?.serviceModeDetails?.[0]?.maxPrice}</td>
                {/* <td>
                  {moment(row?.basicDetails?.endDate).format("D MMM YYYY")}
                </td> */}
                <td>{row?.serviceModeDetails?.[0]?.discountedPrice}</td>
                <td>
                  {row?.serviceModeDetails?.[0]?.isAdvanceRuleApplicable
                    ? "Yes"
                    : "No"}
                </td>
                <td>
                  <div
                    className={`${
                      row?.serviceModeDetails?.[0]?.isModeActive
                        ? "navigo-appoint-non-select-status-active"
                        : "navigo-appoint-non-select-status-inactive"
                    }`}
                  >
                    <span>
                      {row?.serviceModeDetails?.[0]?.isModeActive == 1
                        ? "Active"
                        : "Inactive"}
                    </span>
                  </div>
                </td>
                <td></td>
                <td>
                  <FontIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleMoreClick(e, row)}
                  >
                    more_vert
                  </FontIcon>
                </td>
              </tr>
              {row?.serviceModeDetails?.slice(1)?.map((row, index) => (
                <tr key={index} className="table-row-expanded animated-box">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{row?.modeName}</td>
                  <td>{row?.maxPrice}</td>
                  <td>{row?.discountedPrice}</td>
                  <td>{row?.isAdvanceRuleApplicable ? "Yes" : "No"}</td>
                  <td>
                    <div
                      className={`${
                        row?.isModeActive
                          ? "navigo-appoint-non-select-status-active"
                          : "navigo-appoint-non-select-status-inactive"
                      }`}
                    >
                      <span>
                        {row?.isModeActive == 1 ? "Active" : "Inactive"}
                      </span>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              ))}

              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                  setMenuFor(null);
                }}
              >
                <MenuItem
                  onClick={() => {
                    console.log("ni -> menuFor  ->", menuFor);
                    if (menuFor?.planData && menuFor?.service) {
                      props.setView(4, {
                        mode: "view",
                        planId: menuFor?.planData?.id,
                        serviceId: menuFor?.service?.id,
                      });
                    } else {
                      EventService.emit("showError", {
                        message: `Unable to get plan ID or service details`,
                      });
                    }
                  }}
                >
                  View service
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (menuFor?.planData && menuFor?.service) {
                      props.setView(4, {
                        mode: "update",
                        planId: menuFor?.planData?.id,
                        serviceId: menuFor?.service?.id,
                      });
                    } else {
                      EventService.emit("showError", {
                        message: `Unable to get plan ID or service details`,
                      });
                    }
                  }}
                >
                  Edit service
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (menuFor?.planData && menuFor?.service) {
                      props.setView(4, {
                        mode: "view",
                        editMode: "service-providers",
                        planId: menuFor?.planData?.id,
                        serviceId: menuFor?.service?.id,
                      });
                    } else {
                      EventService.emit("showError", {
                        message: `Unable to get plan ID or service details`,
                      });
                    }
                  }}
                >
                  Assigned service providers
                </MenuItem>
              </Menu>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {openBackdrop && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
          }}
          onClick={() => {
            setOpenFilter(-1);
            setOpenBackdrop(false);
          }}
        ></div>
      )}
    </div>
  );
};

export default ExpandableTableAssignedServices;
