import React, { useRef, useState, useEffect, useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../styles/accountDashboardStyles.scss";
import {
  TextField,
  MenuItem,
  Menu,
  IconButton,
  Radio,
  InputAdornment,
} from "@material-ui/core";
import filterApplied from "../../../../../assets/icons/filter-applied-icon.svg";
import funnelIcon from "../../../../../assets/icons/funnel.svg";
import goIcon from "../../../../../assets/icons/right_arrow_go_btn.svg";
import FontIcon from "react-md/lib/FontIcons";
import OptionsIcon from "react-md-icon/dist/RoundMoreVert";
import EventService from "../../../../../service/event/EventService";
import moment from "moment";
import Button from "react-md/lib/Buttons/Button";
import GlobalSkeletonLoader from "../../../../../components/common/GlobalSkeletonLoader";
import AccountDashboardUtils from "../services/AccountDashboardUtils";
import Helper from "util/Helper";

function AccountDashboard(props) {
  const [serviceList, setServiceList] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuFor, setMenuFor] = useState({});
  const [componentMounted, setComponentMounted] = useState(false);
  const accountDashboardUtils = new AccountDashboardUtils();
  const helper = new Helper();
  const [patientId, setPatientId] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [filterObj, setFilterObj] = useState({
    pageNum: 1,
    pageSize: 30,
  });
  const [onlyPageChange, setOnlyPageChange] = useState(false);
  const [kamOptions, setKamOptions] = useState([]);
  const [kamSelectedOptions, setKamSelectedOptions] = useState([]);
  const [accFilterText, setAccFilterText] = useState("");
  const [accNameFilterText, setAccNameFilterText] = useState("");
  const [accTypeFilter, setAccTypeFilter] = useState(false);

  const handleClick = (event, appointment) => {
    setAnchorEl(event.currentTarget);
    setMenuFor(appointment);
  };

  const [listStatus, setListStatus] = useState(4);
  const [noService, setNoService] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedClientCode, setSelectedClientCode] = useState("All");
  const [totalCount, setTotalCount] = useState(0);
  const [numFrom, setNumFrom] = useState(0);
  const [numTo, setNumTo] = useState(0);
  const [accountNameOptions, setAccountNameOptions] = useState([]);
  const [filterAccountNameOptions, setFilterAccountNameOptions] = useState([]);
  const [accountCodeOptions, setAccountCodeOptions] = useState([]);
  const [filterAccountCodeOptions, setFilterAccountCodeOptions] = useState([]);

  const [dateRangeFilter, setDateRangeFilter] = useState(false);

  const [dateRangeFilterValueFrom, setDateRangeFilterValueFrom] =
    useState(null);
  const [dateRangeFilterValueTo, setDateRangeFilterValueTo] = useState(null);
  const [serviceTypeFilter, setServiceTypeFilter] = useState(false);
  const [creationDateRangeFilterValueFrom, setCreationDateRangeFilterFrom] =
    useState(null);
  const [creationDateRangeFilterValueTo, setCreationDateRangeFilterTo] =
    useState(null);
  const [serviceTypeFilterValue, setServiceTypeFilterValue] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openFilter, setOpenFilter] = useState(0);
  const [maxDate, setMaxDate] = useState("");
  const skip = useRef(30);

  useEffect(() => {
    handleGetAllServices(1, 0, listStatus, selectedClientCode);
    setComponentMounted(true);
    fetchKeyAccountManagers();
  }, []);

  useEffect(() => {
    if (componentMounted && onlyPageChange == true) {
      setFilterObj({ ...filterObj, pageNum: pageNum });
    }
  }, [onlyPageChange]);
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setMaxDate(today);
  }, []);

  useEffect(() => {
    if (componentMounted) {
      skip.current = 30;
      handleGetAllServices(1, 0, listStatus, "");
    }
  }, [filterObj, serviceTypeFilterValue]);
  useEffect(() => {
    if (
      dateRangeFilterValueFrom &&
      dateRangeFilterValueTo &&
      componentMounted
    ) {
      skip.current = 30;
      handleGetAllServices(1, 0, listStatus, "");
      setDateRangeFilter(true);
    }
  }, [dateRangeFilterValueFrom, dateRangeFilterValueTo]);

  useEffect(() => {
    setFilterObj({
      ...filterObj,
      pageNum: 1,
      accountCodeMatch: filterAccountCodeOptions.map((e) => {
        return e.accountCode;
      }),
    });
    setPageNum(1);
  }, [filterAccountCodeOptions]);

  function filterUniqueByAccountCode(arr) {
    const seen = new Map();
    return arr.filter(
      (item) => !seen.has(item.accountCode) && seen.set(item.accountCode, true)
    );
  }

  const handleFetchAccountCodeOpt = (str) => {
    accountDashboardUtils.getAccountsByFilter(
      {
        ...filterObj,
        pageNum: 0,
        pageSize: 0,
        accountCodeMatch: [],
        accountCode: str,
      },
      (res) => {
        setOnlyPageChange(false);
        if (res && res.accounts && res.accounts.length > 0) {
          let allList = [];

          allList = res.accounts.map((service) => {
            return { ...service };
          });
          allList = filterUniqueByAccountCode(allList);
          setAccountCodeOptions(allList);
        } else {
          setAccountCodeOptions([]);
        }
      },
      (err) => {
        setLoading(false);
        setNoService(true);
        EventService.emit("showError", {
          message: "Cannot retrieve account",
        });
      }
    );
  };

  useEffect(() => {
    setFilterObj({
      ...filterObj,
      pageNum: 1,
      accountNameMatch: filterAccountNameOptions.map((e) => {
        return e.accountName;
      }),
    });
    setPageNum(1);
  }, [filterAccountNameOptions]);

  function filterUniqueByAccountName(arr) {
    const seen = new Map();
    return arr.filter(
      (item) => !seen.has(item.accountName) && seen.set(item.accountName, true)
    );
  }

  const handleFetchAccountNameOpt = (str) => {
    accountDashboardUtils.getAccountsByFilter(
      {
        ...filterObj,
        pageNum: 0,
        pageSize: 0,
        accountNameMatch: [],
        accountName: str,
      },
      (res) => {
        setOnlyPageChange(false);
        if (res && res.accounts && res.accounts.length > 0) {
          let allList = [];

          allList = res.accounts.map((service) => {
            return { ...service };
          });
          allList = filterUniqueByAccountName(allList);
          setAccountNameOptions(allList);
        } else {
          setAccountNameOptions([]);
        }
      },
      (err) => {
        setLoading(false);
        setNoService(true);
        EventService.emit("showError", {
          message: "Cannot retrieve accounts",
        });
      }
    );
  };

  const handleGetAllServices = (
    type,
    btnType = 0,
    status = 4,
    clientCode = "",
    dateFlag = true
  ) => {
    setLoading(true);
    accountDashboardUtils.getAccountsByFilter(
      filterObj,
      (res) => {
        setOnlyPageChange(false);
        if (res && res.accounts && res.accounts.length > 0) {
          let allList = [];

          allList = res.accounts.map((service) => {
            return { ...service };
          });
          let localNumFrom, localNumTo;
          if (type === 1) {
            localNumFrom = 1;
            localNumTo = Math.min(30, res.resultCount);
          } else if (type === 2) {
            if (btnType === 0) {
              localNumTo = numFrom - 1;
              localNumFrom = Math.max(numFrom - 30, 1);
            } else {
              localNumFrom = numTo + 1;
              localNumTo = Math.min(numTo + 30, res.resultCount);
            }
          } else if (type === 3) {
            localNumFrom = numFrom;
            localNumTo = numTo;
          }
          setServiceList([...allList]);
          setNoService(false);
          setNumFrom(localNumFrom);
          setNumTo(localNumTo);
          setTotalCount(res.totalCount);
          setLoading(false);
        } else {
          setServiceList([]);
          setNoService(true);
          setLoading(false);
        }
      },
      (err) => {
        setLoading(false);
        setNoService(true);
        EventService.emit("showError", {
          message: "Cannot retrieve services",
        });
      }
    );
  };

  const handleAddNewAccount = () => {
    props.setView(1, {
      mode: "create",
    });
  };

  const handleEditAppointment = (ele, opt) => {
    if (opt == 1) {
      props.setView(1, {
        mode: "view",
        accountId: ele.id,
      });
    }
    if (opt == 2) {
      props.setView(1, {
        mode: "update",
        accountId: ele.id,
      });
    }
    if (opt == 3) {
      props.setView(2, {
        mode: "assign",
        accountId: ele.id,
      });
    }
  };

  const handlePrevNextAppoint = (arg) => {
    setOnlyPageChange(true);
    if (arg) {
      setPageNum((prevPage) => prevPage + 1);
    } else {
      if (pageNum > 1) {
        setPageNum((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
      }
    }
  };

  const getAccountTypeName = (code) => {
    switch (code) {
      case "01":
        return "Insurance";
      case "02":
        return "Pharma";
      case "03":
        return "Corporate";
      case "04":
        return "Zyla";
      default:
        return "Unknown";
    }
  };

  useEffect(() => {
    if (!patientId && !appointmentId && !phoneNumber && componentMounted) {
      handleGetAllServices(1, 0, listStatus, "");
    }
  }, [patientId, appointmentId, phoneNumber]);

  const fetchKeyAccountManagers = () => {
    const onResponse = (res) => {
      let obj = res
        .filter((e) => e.team === "BD" && e.status === 4)
        .map((e) => ({
          name: `${e.firstName} ${e.lastName}`,
          value: e.id,
        }));

      setKamOptions(obj);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    accountDashboardUtils.fetchKeyAccountManagers(onResponse, onError);
  };

  return (
    <div className="account-dashboard-table-container">
      <div className="navigo-appoint-container">
        <div className="navigo-appoint-section-3">
          {/* <div
            className="navigo-appoint-section-1"
            onClick={() => {
              helper.navigateTo(props, "/miscellaneous");
            }}
          >
            <FontIcon>arrow_back</FontIcon>
          </div> */}
          <h1 className="navigo-appoint-section-2">Account Dashboard</h1>

          <div className="navigo-appoint-section-4-bottom-button-container">
            <button
              className="navigo-appoint-section-4-bottom-button"
              onClick={handleAddNewAccount}
            >
              Add New
            </button>
          </div>
        </div>

        <div className="navigo-appoint-section-5">
          <div className="navigo-appoint-section-5-grid-container">
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Account <br /> Id
              </p>
              {!accTypeFilter && (
                <img
                  src={
                    filterAccountCodeOptions.length > 0
                      ? filterApplied
                      : funnelIcon
                  }
                  onClick={() => {
                    setOpenBackdrop(true);
                    setOpenFilter(1);
                  }}
                />
              )}

              {openFilter === 1 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup-acccountid">
                  <Autocomplete
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                    multiple
                    id="multiple-limit-acc-name"
                    options={accountCodeOptions}
                    getOptionLabel={(option) => option.accountCode}
                    value={filterAccountCodeOptions}
                    onChange={(e, v) => {
                      if (v) {
                        setFilterAccountCodeOptions(v);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        onChange={(e, v) => {
                          handleFetchAccountCodeOpt(e.target.value);
                        }}
                        {...params}
                        placeholder=""
                        className="autocomplete-text-field-class"
                      />
                    )}
                  />
                  {/* <div
                    style={{ display: "flex" }}
                    onClick={() => {
                      setFilterObj({
                        ...filterObj,
                        pageNum: 1,
                        accountCode: accFilterText,
                      });
                      setPageNum(1);
                    }}
                  >
                    <img
                      src={goIcon}
                      alt="Go"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </div> */}
                </div>
              )}
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section">
              <p>
                Account
                <br /> name
              </p>
              <img
                src={
                  filterAccountNameOptions.length > 0
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(2);
                }}
              />
              {openFilter === 2 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup-acccount-name">
                  <Autocomplete
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                    multiple
                    id="multiple-limit-acc-name"
                    options={accountNameOptions}
                    getOptionLabel={(option) => option.accountName}
                    value={filterAccountNameOptions}
                    onChange={(e, v) => {
                      if (v) {
                        setFilterAccountNameOptions(v);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        onChange={(e, v) => {
                          handleFetchAccountNameOpt(e.target.value);
                        }}
                        {...params}
                        placeholder=""
                        className="autocomplete-text-field-class"
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section">
              <p>
                Account <br /> type
              </p>
              {filterAccountCodeOptions.length == 0 && (
                <img
                  src={accTypeFilter ? filterApplied : funnelIcon}
                  onClick={() => {
                    setOpenBackdrop(true);
                    setOpenFilter(3);
                  }}
                />
              )}

              {openFilter === 3 && (
                <div className="mp-service-dashboard-acc-type-filter">
                  <div>
                    <Radio
                      label="Insurance"
                      checked={
                        filterObj.accountCode
                          ? filterObj.accountCode.slice(0, 2) == "01"
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          accountCode: "01",
                        });
                        setPageNum(1);
                        setAccTypeFilter(true);
                      }}
                    />
                    <label className="label-radio-btn">Insurance</label>
                  </div>
                  <div>
                    <Radio
                      label="Pharma"
                      checked={
                        filterObj.accountCode
                          ? filterObj.accountCode.slice(0, 2) == "02"
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          accountCode: "02",
                        });
                        setPageNum(1);
                        setAccTypeFilter(true);
                      }}
                    />
                    <label className="label-radio-btn">Pharma</label>
                  </div>
                  <div>
                    <Radio
                      label="Corporate"
                      checked={
                        filterObj.accountCode
                          ? filterObj.accountCode.slice(0, 2) == "03"
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          accountCode: "03",
                        });
                        setPageNum(1);
                        setAccTypeFilter(true);
                      }}
                    />
                    <label className="label-radio-btn">Corporate</label>
                  </div>
                  <div>
                    <Radio
                      label="Zyla"
                      checked={
                        filterObj.accountCode
                          ? filterObj.accountCode.slice(0, 2) == "04"
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          accountCode: "04",
                        });
                        setPageNum(1);
                        setAccTypeFilter(true);
                      }}
                    />
                    <label className="label-radio-btn">Zyla</label>
                  </div>

                  <Button
                    className="clear-navigo-booked-for"
                    onClick={() => {
                      let removedFilterObj = filterObj;
                      delete removedFilterObj.accountCode;
                      setFilterObj({
                        ...removedFilterObj,
                        pageNum: 1,
                      });
                      setPageNum(1);
                      setAccTypeFilter(false);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              )}
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Creation <br />
                on
              </p>
              <img
                src={
                  filterObj?.createdAt?.from && filterObj?.createdAt?.to
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(4);
                  // setOpenDateRangeFilter(true);
                }}
              />
              {openFilter === 4 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                  <div className="navigo-appointment-date-range-filter ">
                    <p>From</p>
                    <TextField
                      type="date"
                      style={{ width: "90%" }}
                      className="dob"
                      id="dob"
                      onChange={(v) => {
                        if (v.target.value) {
                          setCreationDateRangeFilterFrom(v.target.value);
                          setFilterObj({
                            ...filterObj,
                            pageNum: 1,
                            createdAt: {
                              ...filterObj.createdAt,
                              from: moment(v.target.value)
                                .utc()
                                .startOf("day")
                                .add(1, "day")
                                .toISOString(),
                            },
                          });
                          setPageNum(1);
                        }
                      }}
                      inputProps={{ max: maxDate }}
                      value={creationDateRangeFilterValueFrom}
                    ></TextField>
                  </div>
                  <div className="navigo-appointment-date-range-filter ">
                    <p>To</p>
                    <TextField
                      type="date"
                      defaultValue={""}
                      style={{ width: "90%" }}
                      className="dob"
                      id="dob"
                      onChange={(v) => {
                        if (v.target.value) {
                          setCreationDateRangeFilterTo(v.target.value);
                          setFilterObj({
                            ...filterObj,
                            pageNum: 1,
                            createdAt: {
                              ...filterObj.createdAt,
                              to: moment(v.target.value)
                                .utc()
                                .endOf("day")
                                .add(1, "day")
                                .toISOString(),
                            },
                          });
                          setPageNum(1);
                        }
                      }}
                      inputProps={{ max: maxDate }}
                      value={creationDateRangeFilterValueTo}
                    ></TextField>
                  </div>

                  <Button
                    className=""
                    onClick={() => {
                      let removedFilterObj = filterObj;
                      delete removedFilterObj.createdAt;
                      setFilterObj({
                        ...removedFilterObj,
                        pageNum: 1,
                      });
                      setCreationDateRangeFilterFrom(null);
                      setCreationDateRangeFilterTo(null);
                      setPageNum(1);
                      setOpenFilter(0);
                      setOpenBackdrop(false);
                    }}
                  >
                    <FontIcon className="">close</FontIcon>
                  </Button>
                </div>
              )}
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Key account <br /> manager
              </p>
              <img
                src={serviceTypeFilter ? filterApplied : funnelIcon}
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(5);
                }}
              />
              {openFilter === 5 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                  <Autocomplete
                    multiple
                    id="account-dashboard-kam-filter"
                    className="appointment-dialog-client-code-filter-class"
                    options={kamOptions}
                    value={kamSelectedOptions}
                    onChange={(event, selectedOptions) => {
                      setKamSelectedOptions(selectedOptions);
                      let filt = selectedOptions.map((e) => e.value);
                      setFilterObj({
                        ...filterObj,
                        pageNum: 1,
                        managerIds: filt,
                      });
                      setPageNum(1);
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason == "clear") {
                        setKamSelectedOptions([]);
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"Enter key account manager"}
                      />
                    )}
                  />
                </div>
              )}
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Active <br />
                plan
              </p>
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Users on <br /> account
              </p>
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>Status</p>
              <img
                src={
                  filterObj?.status && filterObj?.status != ""
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(6);
                  // setOpenStatusFilter(true);
                }}
              />
              {openFilter === 6 && (
                <div className="mp-service-dashboard-status-filter">
                  <div>
                    <Radio
                      label="active"
                      checked={
                        filterObj.status
                          ? filterObj.status == "active"
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          status: "active",
                        });
                        setPageNum(1);
                      }}
                    />
                    <label className="label-radio-btn">Active</label>
                  </div>
                  <div>
                    <Radio
                      label="inactive"
                      checked={
                        filterObj.status
                          ? filterObj.status == "inactive"
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          status: "inactive",
                        });
                        setPageNum(1);
                      }}
                    />
                    <label className="label-radio-btn">Inactive</label>
                  </div>

                  <Button
                    className="clear-navigo-booked-for"
                    onClick={() => {
                      let removedFilterObj = filterObj;
                      delete removedFilterObj.status;
                      setFilterObj({
                        ...removedFilterObj,
                        pageNum: 1,
                      });
                      setPageNum(1);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
        <div className="navigo-appoint-section-6">
          {serviceList && serviceList.length > 0 && (
            <div className="navigo-appoint-bottom-bar">
              <Button
                className="navigo-appoint-bottom-bar-button"
                onClick={() => {
                  handlePrevNextAppoint(0);
                }}
                disabled={pageNum == 1 || loading}
              >
                <FontIcon className="navigo-count-icon">arrow_drop_up</FontIcon>
              </Button>
              <div className="navigo-appoint-bottom-bar-text">
                Showing&nbsp;
                <span
                  style={{
                    color: "#ef7c01",
                  }}
                >
                  {(pageNum - 1) * pageSize + 1}&nbsp;- &nbsp;
                  {totalCount > pageNum * pageSize
                    ? pageNum * pageSize
                    : totalCount}
                </span>
                <span>&nbsp;of&nbsp;</span>
                <span>{` ${totalCount}`}</span>
              </div>
              <Button
                className="navigo-appoint-bottom-bar-button"
                onClick={() => {
                  handlePrevNextAppoint(1);
                }}
                disabled={
                  loading ||
                  pageNum * pageSize > totalCount ||
                  (totalCount > pageNum * pageSize
                    ? pageNum * pageSize
                    : totalCount) == totalCount
                }
              >
                <FontIcon className="navigo-count-icon">
                  arrow_drop_down
                </FontIcon>
              </Button>
            </div>
          )}
          <div className="navigo-appoint-section-6-list">
            {serviceList &&
              serviceList.map((ele) => {
                return (
                  <div
                    className="navigo-appoint-section-6-grid-container"
                    key={ele.id}
                  >
                    <div className="general-font-class">
                      {`${ele.accountCode}`}
                    </div>
                    <div className="general-font-class ">{ele.accountName}</div>
                    <div className="general-font-class ">
                      {getAccountTypeName(ele.accountCode.slice(0, 2))}
                    </div>
                    <div className="general-font-class ">
                      {moment(ele.createdAt).format("DD MMM YYYY")}
                    </div>
                    <div className="general-font-class ">{ele.managerName}</div>

                    <div className="general-font-class">
                      {/* {moment(ele.createdAt).format("DD MMM YYYY")} */}{" "}
                    </div>

                    <div className="general-font-class">
                      {/* {`${ele.addressRequired ? "Yes" : "No"}`} */}{" "}
                    </div>
                    <div className="navigo-appoint-section-6-grid-status">
                      <div
                        className={`${
                          ele.status
                            ? "navigo-appoint-non-select-status-active"
                            : "navigo-appoint-non-select-status-inactive"
                        } `}
                      >
                        <span>{ele.status ? "Active" : "Inactive"}</span>
                      </div>
                    </div>

                    <IconButton
                      onClick={(e) => handleClick(e, ele)}
                      className="navigo-appoint-section-6-grid-edit-call"
                    >
                      <OptionsIcon />
                    </IconButton>
                  </div>
                );
              })}
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  handleEditAppointment(menuFor, 1);
                  setAnchorEl(null);
                }}
              >
                View account
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleEditAppointment(menuFor, 2);
                  setAnchorEl(null);
                }}
              >
                Edit account
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleEditAppointment(menuFor, 3);
                  setAnchorEl(null);
                }}
              >
                Assigned plans
              </MenuItem>
            </Menu>
          </div>
          {loading && <GlobalSkeletonLoader />}
          {loading === false && noService && (
            <div className="no-appointments-container">
              <p>No account found!</p>
            </div>
          )}
        </div>
        {openBackdrop && (
          <div
            className="navigo-appointment-backdrop-class"
            onClick={() => {
              setOpenFilter(0);
              setOpenBackdrop(false);
            }}
          ></div>
        )}
      </div>
    </div>
  );
}

export default AccountDashboard;
