import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";
import Helper from "util/Helper";

export class CreateServiceProviderUtil {
  constructor() {
    this.apiService = new APIService();
    this.helper = new Helper();
  }

  fetchDoctorDetails(body, onResponse, onError) {
    let url = APIConfig.followUp.doctorNameById.format(body);
    return this.apiService.get(url, onResponse, onError);
  }
  getLanguage(onResponse, onError) {
    let url = APIConfig.myPlan.languages.format(true);
    return this.apiService.get(url, onResponse, onError);
  }
  getCountries(onResponse, onError) {
    let url = APIConfig.myPlan.countryCodes;
    return this.apiService.get(url, onResponse, onError);
  }
  getPincodeDetails(body, onResponse, onError) {
    let url = APIConfig.patient.pinCode.format(body);
    return this.apiService.get(url, onResponse, onError);
  }
  createServiceProvider(body, onResponse, onError) {
    let url = APIConfig.myPlan.serviceProvider.create;
    return this.apiService.post(url, body, onResponse, onError);
  }
  updateServiceProvider(id, body, onResponse, onError) {
    let url = APIConfig.myPlan.serviceProvider.update.format(id);
    return this.apiService.put(url, body, onResponse, onError);
  }
  getGroupVendor(onResponse, onError) {
    let url = APIConfig.myPlan.serviceProvider.getGroupVendor.format(true);
    return this.apiService.get(url, onResponse, onError);
  }
}

export default CreateServiceProviderUtil;
