import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";
import Helper from "util/Helper";

export class AccountDashboardUtils {
  constructor() {
    this.apiService = new APIService();
    this.helper = new Helper();
  }
  getAccountsByFilter(body, onResponse, onError) {
    let url = APIConfig.myPlan.accountDashboard.getAccountsByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }
  getAccountsById(id, onResponse, onError) {
    let url = APIConfig.myPlan.accountDashboard.getAccountById.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  fetchKeyAccountManagers(onResponse, onError) {
    let url = APIConfig.bridgeEmployee.getAll;
    return this.apiService.get(url, onResponse, onError);
  }
  uploadImg = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.uploadContent;
    return this.apiService.postWithFormData(url, body, onResponse, onError);
  };
  saveAccount(body, onResponse, onError) {
    let url = APIConfig.myPlan.accountDashboard.create;
    return this.apiService.post(url, body, onResponse, onError);
  }
  updateAccount(id, body, onResponse, onError) {
    let url = APIConfig.myPlan.accountDashboard.updateAccount.format(id);
    return this.apiService.put(url, body, onResponse, onError);
  }
}

export default AccountDashboardUtils;
