import React, { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, Autocomplete } from "react-md";
import ServiceProviderConsultation from "./ServiceProviderConsultation";
import ServiceProviderHealthCheckup from "./ServiceProviderHealthCheckup";
import "../styles/index.css";
import FontIcon from "react-md/lib/FontIcons";
import CreateServiceUtil from "../services/CreateServiceUtil";
import EventService from "../../../../../service/event/EventService";
import CreateServiceProviderUtil from "../services/CreateServiceProviderUtil";
import { useCallback } from "react";

export const CreateServiceProvider = (props) => {
  const [serviceProviderView, setServiceProviderView] = useState(0);
  const createServiceUtil = new CreateServiceUtil();
  const createServiceProviderUtil = new CreateServiceProviderUtil();
  const [mode, setMode] = useState(null);
  const [servicesTypeArr, setServicesTypeArr] = useState([]);
  const [servicesTypeObj, setServicesTypeObj] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState(null);
  const [providerToCreate, setProviderToCreate] = useState({
    serviceTypeId: "",
    serviceSubtypeId: "",
    isActive: "",
    consultationInfo: {
      identityDetails: {},
      contactDetails: {},
      address: {},
      reviewData: {},
      availability: {
        config: {
          slotDurationInMinutes: 0,
          bookingPerSlot: 0,
          minBookingDays: 0,
          maxAdvBookingDays: 0,
        },
        schedule: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
        unavailableTime: [],
        availableTime: [],
      },
    },
    checkupInfo: {
      identityDetails: {},
      contactDetails: {},
      address: {},
      reviewData: {},
      vendorGroup: {},
      availability: {
        config: {
          slotDurationInMinutes: 0,
          bookingPerSlot: 0,
          minBookingDays: 0,
          maxAdvBookingDays: 0,
        },
        schedule: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
        unavailableTime: [],
        availableTime: [],
      },
    },
  });
  //-----

  useEffect(() => {
    if (props.data.mode == "create") {
      setMode(1);
    } else if (props.data.mode == "view") {
      getServiceProvider();
      setMode(2);
    } else if (props.data.mode == "update") {
      setMode(3);
      getServiceProvider();
    }
    getServiceType();
  }, []);

  const updatePI = useCallback((newValue, ind) => {
    setProviderToCreate((prev) => {
      console.log("pr- prev and new=", prev, newValue, ind);

      return { ...prev, ...newValue };
    });
  }, []);
  useEffect(() => {
    if (
      (props.data.mode == "view" || props.data.mode == "update") &&
      providerToCreate.serviceTypeName &&
      countries != null
    ) {
      if (
        providerToCreate.serviceTypeId == 1 &&
        providerToCreate.consultationInfo.identityDetails.name
      ) {
        setServiceProviderView(1);
      } else if (
        providerToCreate.serviceTypeId == 2 &&
        providerToCreate.checkupInfo.identityDetails.name
      ) {
        setServiceProviderView(2);
      }
    }
  }, [providerToCreate, countries]);

  useEffect(() => {
    let dropdownObj = servicesTypeArr.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
    setServicesTypeObj(dropdownObj);
  }, [servicesTypeArr]);

  //--------------------
  let calendarHeadings = [
    "Master weekly schedule of the provider",
    "Service provider unavailability beyond master weekly schedule",
    "Service provider availability beyond master weekly schedule",
    "availability beyond default weekly schedule",
  ];

  const phoneNoLen = (cc) => {
    return countries?.find((c) => c.code == cc).phoneNoLen;
  };

  let getServiceType = () => {
    let onResponse = (res) => {
      setServicesTypeArr((prev) => {
        const updatedState = [...res];
        return updatedState;
      });
      getCountryCodes();
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceUtil.getServiceType(true, onResponse, onError);
  };
  let getCountryCodes = () => {
    let onResponse = (res) => {
      setCountries(res);
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceProviderUtil.getCountries(onResponse, onError);
  };
  let onExit = () => {
    setServiceProviderView(0);
    setMode(null);
    setServicesTypeArr([]);
    setServicesTypeObj([]);
    setProviderToCreate({});
    props.setView(4);
  };

  let getServiceProvider = () => {
    let onResponse = (res) => {
      let status = res.isActive == true ? "true" : "false";
      let body = { ...res, isActive: status };
      if (
        body.serviceTypeId == 1 &&
        body.consultationInfo.contactDetails.callingNumber.countryCode == 0
      ) {
        body = {
          ...body,
          consultationInfo: {
            ...body.consultationInfo,
            contactDetails: {
              ...body.consultationInfo.contactDetails,
              callingNumber: { countryCode: 91, phoneNo: "" },
            },
          },
        };
      }
      setProviderToCreate(body);
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceUtil.getServiceProviderById(
      props.data.serviceProviderId,
      onResponse,
      onError
    );
  };

  let verifyData = () => {
    if (serviceProviderView == 0) {
      EventService.emit("showError", {
        message: "Please select service type",
      });
      return;
    } else {
      if (serviceProviderView == 1) {
        const hasEmptySlot = Object.keys(
          providerToCreate.consultationInfo.availability.schedule
        ).some((day) =>
          providerToCreate.consultationInfo.availability.schedule[day].some(
            (slot) => slot.start === "" || slot.end === ""
          )
        );

        if (
          providerToCreate.serviceTypeId == null ||
          providerToCreate.serviceTypeId == ""
        ) {
          EventService.emit("showError", {
            message: "Please select service type",
          });
          return;
        } else if (
          providerToCreate.isActive == null ||
          providerToCreate.isActive == ""
        ) {
          EventService.emit("showError", {
            message: "Please select service provider status",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.identityDetails.doctorId == null ||
          providerToCreate.consultationInfo.identityDetails.doctorId == ""
        ) {
          EventService.emit("showError", {
            message: "Please fill the doctor ID",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.identityDetails.licenseNo == null ||
          providerToCreate.consultationInfo.identityDetails.licenseNo.trim() ==
            ""
        ) {
          EventService.emit("showError", {
            message: "Doctor license number is empty, enter correct expert ID",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.identityDetails.namePrefix ==
            null ||
          providerToCreate.consultationInfo.identityDetails.namePrefix.trim() ==
            ""
        ) {
          EventService.emit("showError", {
            message: "Doctor prefix is empty, enter correct expert ID",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.identityDetails.name == null ||
          providerToCreate.consultationInfo.identityDetails.name.trim() == ""
        ) {
          EventService.emit("showError", {
            message: "Please update service provider name",
          });
          return;
        } else if (
          providerToCreate.serviceSubtypeId == null ||
          providerToCreate.serviceSubtypeId == ""
        ) {
          EventService.emit("showError", {
            message: "Please select service subtype",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.identityDetails.mobileCover ==
            null ||
          providerToCreate.consultationInfo.identityDetails.mobileCover == ""
        ) {
          EventService.emit("showError", {
            message: "Please update Image (Mobile view) for the expert ID",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.identityDetails.practiceStartDate ==
            null ||
          providerToCreate.consultationInfo.identityDetails.practiceStartDate ==
            ""
        ) {
          EventService.emit("showError", {
            message: "Please fill Service provider practice start date",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.identityDetails.languages == null ||
          providerToCreate.consultationInfo.identityDetails.languages.length ==
            0
        ) {
          EventService.emit("showError", {
            message: "Please fill languages known",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.identityDetails.shortdescription ==
            null ||
          providerToCreate.consultationInfo.identityDetails.shortdescription.trim() ==
            ""
        ) {
          EventService.emit("showError", {
            message: "Please fill Service provider short description",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.identityDetails.longDescription ==
            null ||
          providerToCreate.consultationInfo.identityDetails.longDescription.trim() ===
            "" ||
          providerToCreate.consultationInfo.identityDetails.longDescription.trim() ===
            "<p></p>" ||
          providerToCreate.consultationInfo.identityDetails.longDescription.trim() ===
            "<p><br></p>"
        ) {
          EventService.emit("showError", {
            message: "Please fill Service provider long description",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.contactDetails.contactNumber
            .countryCode == null ||
          providerToCreate.consultationInfo.contactDetails.contactNumber
            .countryCode == "" ||
          !(
            Number.isInteger(
              providerToCreate.consultationInfo.contactDetails.contactNumber
                .countryCode
            ) &&
            providerToCreate.consultationInfo.contactDetails.contactNumber
              .countryCode > 0
          )
        ) {
          EventService.emit("showError", {
            message: "Please update service provider phone country code",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.contactDetails.contactNumber
            .phoneNo == null ||
          providerToCreate.consultationInfo.contactDetails.contactNumber
            .phoneNo == "" ||
          !(
            Number.isInteger(
              providerToCreate.consultationInfo.contactDetails.contactNumber
                .phoneNo
            ) &&
            providerToCreate.consultationInfo.contactDetails.contactNumber
              .phoneNo > 0
          )
        ) {
          EventService.emit("showError", {
            message: "Please update service provider phone number",
          });
          return;
        } else if (
          providerToCreate.consultationInfo.contactDetails
            .alternateContactNumber &&
          providerToCreate.consultationInfo.contactDetails
            .alternateContactNumber.length != 0 &&
          providerToCreate.consultationInfo.contactDetails.alternateContactNumber.some(
            (item) =>
              item.phoneNo != "" &&
              item.phoneNo.toString().length != phoneNoLen(item.countryCode)
          )
        ) {
          EventService.emit("showError", {
            message: `Wrong alternate phone number length`,
          });
          return;
        } else if (
          providerToCreate?.consultationInfo?.contactDetails?.callingNumber
            ?.phoneNo != "" &&
          providerToCreate?.consultationInfo?.contactDetails?.callingNumber?.phoneNo.toString()
            .length !=
            phoneNoLen(
              providerToCreate?.consultationInfo?.contactDetails?.callingNumber
                ?.countryCode
            )
        ) {
          EventService.emit("showError", {
            message: `Calling number should be of length ${phoneNoLen(
              providerToCreate?.consultationInfo?.contactDetails?.callingNumber
                ?.countryCode
            )}`,
          });
          return;
        } else if (
          providerToCreate.consultationInfo.contactDetails.spoc != null &&
          providerToCreate.consultationInfo.contactDetails.spoc.length > 0 &&
          providerToCreate.consultationInfo.contactDetails.spoc.some(
            (item) =>
              !(item.emailId == "" && item.name == "" && item.phoneNo == "") &&
              item.phoneNo.toString().length != phoneNoLen(item.countryCode)
          )
        ) {
          EventService.emit("showError", {
            message: `Wrong Spoc phone number length`,
          });
          return;
        } else if (hasEmptySlot) {
          EventService.emit("showError", {
            message: "Please fill all the slots in the schedule",
          });
          return;
        } else {
          let body = providerToCreate;
          body = {
            ...body,
            isActive: body.isActive == "true" ? true : false,
            checkupInfo: null,
          };
          if (
            providerToCreate?.consultationInfo?.contactDetails?.callingNumber
              ?.phoneNo == ""
          ) {
            body = {
              ...body,
              consultationInfo: {
                ...body.consultationInfo,
                contactDetails: {
                  ...body.consultationInfo.contactDetails,
                  callingNumber: null,
                },
              },
            };
          }
          if (
            providerToCreate.consultationInfo.contactDetails
              .alternateContactNumber &&
            providerToCreate.consultationInfo.contactDetails
              .alternateContactNumber.length != 0
          ) {
            let newAltContact =
              providerToCreate.consultationInfo.contactDetails.alternateContactNumber.filter(
                (e) => {
                  return e.phoneNo == "" ? false : true;
                }
              );
            body = {
              ...body,
              consultationInfo: {
                ...body.consultationInfo,
                contactDetails: {
                  ...body.consultationInfo.contactDetails,
                  alternateContactNumber: newAltContact,
                },
              },
            };
          }
          if (
            body.consultationInfo.contactDetails.spoc != null &&
            body.consultationInfo.contactDetails.spoc.length > 0
          ) {
            let newSpoc = body.consultationInfo.contactDetails.spoc.filter(
              (e) => !(e.emailId == "" && e.name == "" && e.phoneNo == "")
            );

            body = {
              ...body,
              consultationInfo: {
                ...body.consultationInfo,
                contactDetails: {
                  ...body.consultationInfo.contactDetails,
                  spoc: newSpoc,
                },
              },
            };
          }
          console.log("pr- going to save body=", body);

          mode == 3
            ? updateServiceProvider(body)
            : createNewServiceProvider(body);
        }
      } else if (serviceProviderView == 2) {
        const hasEmptySlot = Object.keys(
          providerToCreate.checkupInfo.availability.schedule
        ).some((day) =>
          providerToCreate.checkupInfo.availability.schedule[day].some(
            (slot) => slot.start === "" || slot.end === ""
          )
        );
        if (
          providerToCreate.serviceTypeId == null ||
          providerToCreate.serviceTypeId == ""
        ) {
          EventService.emit("showError", {
            message: "Please select service type",
          });
          return;
        } else if (
          providerToCreate.serviceSubtypeId == null ||
          providerToCreate.serviceSubtypeId == ""
        ) {
          EventService.emit("showError", {
            message: "Please select service subtype",
          });
          return;
        } else if (
          providerToCreate.isActive == null ||
          providerToCreate.isActive == ""
        ) {
          EventService.emit("showError", {
            message: "Please select service provider status",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.identityDetails.name == null ||
          providerToCreate.checkupInfo.identityDetails.name.trim() == ""
        ) {
          EventService.emit("showError", {
            message: "Please fill the service provider name",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.identityDetails.shortdescription ==
            null ||
          providerToCreate.checkupInfo.identityDetails.shortdescription.trim() ==
            ""
        ) {
          EventService.emit("showError", {
            message: "Please fill Service provider short description",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.identityDetails.mobileCover == null ||
          providerToCreate.checkupInfo.identityDetails.mobileCover == ""
        ) {
          EventService.emit("showError", {
            message: "Please upload Image (Mobile view)",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.contactDetails.bookingEmailId == null ||
          providerToCreate.checkupInfo.contactDetails.bookingEmailId.trim() ==
            ""
        ) {
          EventService.emit("showError", {
            message: "Please fill Service provider booking Email Id",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.contactDetails.spoc == undefined
        ) {
          EventService.emit("showError", {
            message: "Please fill all SPOC 1 details",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.contactDetails.spoc[0].name.trim() == ""
        ) {
          EventService.emit("showError", {
            message: "Please fill all SPOC 1 name",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.contactDetails.spoc != null &&
          providerToCreate.checkupInfo.contactDetails.spoc.length > 0 &&
          providerToCreate.checkupInfo.contactDetails.spoc.some(
            (item) =>
              !(item.emailId == "" && item.name == "" && item.phoneNo == "") &&
              item.phoneNo.toString().length != phoneNoLen(item.countryCode)
          )
        ) {
          EventService.emit("showError", {
            message: `Wrong Spoc phone number length`,
          });
          return;
        } else if (
          !(
            typeof providerToCreate.checkupInfo.contactDetails.spoc[0]
              .phoneNo === "number" &&
            providerToCreate.checkupInfo.contactDetails.spoc[0].phoneNo > 0 &&
            Number.isInteger(
              providerToCreate.checkupInfo.contactDetails.spoc[0].phoneNo
            ) &&
            providerToCreate.checkupInfo.contactDetails.spoc[0].phoneNo.toString()
              .length === 10
          )
        ) {
          EventService.emit("showError", {
            message: "Please fill SPOC 1 phone number of length 10",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.contactDetails.spoc[0].emailId.trim() ==
          ""
        ) {
          EventService.emit("showError", {
            message: "Please fill  SPOC 1 email",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.address.pincode == null ||
          providerToCreate.checkupInfo.address.pincode == "" ||
          !(
            typeof providerToCreate.checkupInfo.address.pincode === "number" &&
            providerToCreate.checkupInfo.address.pincode > 0 &&
            Number.isInteger(providerToCreate.checkupInfo.address.pincode) &&
            providerToCreate.checkupInfo.address.pincode.toString().length === 6
          )
        ) {
          EventService.emit("showError", {
            message: "Please fill 6 digit pincode code in the address",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.address.city == null ||
          providerToCreate.checkupInfo.address.city.trim() == ""
        ) {
          EventService.emit("showError", {
            message: "Please fill city in the address",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.address.district == null ||
          providerToCreate.checkupInfo.address.district.trim() == ""
        ) {
          EventService.emit("showError", {
            message: "Please fill district in the address",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.address.state == null ||
          providerToCreate.checkupInfo.address.state.trim() == ""
        ) {
          EventService.emit("showError", {
            message: "Please fill state in the address",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.address.landmark == null ||
          providerToCreate.checkupInfo.address.landmark.trim() == ""
        ) {
          EventService.emit("showError", {
            message: "Please fill locality in the address",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.availability.config
            .slotDurationInMinutes == null ||
          providerToCreate.checkupInfo.availability.config
            .slotDurationInMinutes == "" ||
          !(
            Number.isInteger(
              providerToCreate.checkupInfo.availability.config
                .slotDurationInMinutes
            ) &&
            providerToCreate.checkupInfo.availability.config
              .slotDurationInMinutes > 0
          )
        ) {
          EventService.emit("showError", {
            message: "Please fill slot duration in minutes",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.availability.config.bookingPerSlot ==
            null ||
          providerToCreate.checkupInfo.availability.config.bookingPerSlot ==
            "" ||
          !(
            Number.isInteger(
              providerToCreate.checkupInfo.availability.config.bookingPerSlot
            ) &&
            providerToCreate.checkupInfo.availability.config.bookingPerSlot > 0
          )
        ) {
          EventService.emit("showError", {
            message: "Please fill booking per slot",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.availability.config.minBookingDays ==
            null ||
          providerToCreate.checkupInfo.availability.config.minBookingDays ==
            "" ||
          !(
            Number.isInteger(
              providerToCreate.checkupInfo.availability.config.minBookingDays
            ) &&
            providerToCreate.checkupInfo.availability.config.minBookingDays > 0
          )
        ) {
          EventService.emit("showError", {
            message: "Please fill min booking notice",
          });
          return;
        } else if (
          providerToCreate.checkupInfo.availability.config.maxAdvBookingDays ==
            null ||
          providerToCreate.checkupInfo.availability.config.maxAdvBookingDays ==
            "" ||
          !(
            Number.isInteger(
              providerToCreate.checkupInfo.availability.config.maxAdvBookingDays
            ) &&
            providerToCreate.checkupInfo.availability.config.maxAdvBookingDays >
              0
          )
        ) {
          EventService.emit("showError", {
            message: "Please fill max advance booking days",
          });
          return;
        } else if (hasEmptySlot) {
          EventService.emit("showError", {
            message: "Please fill all the slots in the schedule",
          });
          return;
        } else {
          let body = providerToCreate;
          body = {
            ...body,
            isActive: body.isActive == "true" ? true : false,
            consultationInfo: null,
          };
          if (
            body.checkupInfo.contactDetails.alternateBookingEmails != null &&
            body.checkupInfo.contactDetails.alternateBookingEmails.length > 0
          ) {
            let newAltEmail =
              body.checkupInfo.contactDetails.alternateBookingEmails.filter(
                (e) => e != ""
              );

            body = {
              ...body,
              checkupInfo: {
                ...body.checkupInfo,
                contactDetails: {
                  ...body.checkupInfo.contactDetails,
                  alternateBookingEmails: newAltEmail,
                },
              },
            };
          }
          if (
            body.checkupInfo.contactDetails.spoc != null &&
            body.checkupInfo.contactDetails.spoc.length > 0
          ) {
            let newSpoc = body.checkupInfo.contactDetails.spoc.filter(
              (e) => !(e.emailId == "" && e.name == "" && e.phoneNo == "")
            );

            body = {
              ...body,
              checkupInfo: {
                ...body.checkupInfo,
                contactDetails: {
                  ...body.checkupInfo.contactDetails,
                  spoc: newSpoc,
                },
              },
            };
          }

          console.log("pr- boyd to save=", body);

          if (mode == 3) {
            body = {
              ...body,
              consultationInfo: null,
            };

            updateServiceProvider(body);
          } else {
            createNewServiceProvider(body);
          }
        }
      }
    }
  };
  const createNewServiceProvider = (payload) => {
    setLoading(true);
    let onResponse = (res) => {
      EventService.emit("showError", {
        message: `Service provider created`,
      });
      setLoading(false);
      onExit();
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
      setLoading(false);
    };
    createServiceProviderUtil.createServiceProvider(
      payload,
      onResponse,
      onError
    );
  };
  const updateServiceProvider = (payload) => {
    setLoading(true);
    let onResponse = (res) => {
      EventService.emit("showError", {
        message: `Service provider updated`,
      });
      setLoading(false);
      onExit();
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
      setLoading(false);
    };
    createServiceProviderUtil.updateServiceProvider(
      props.data.serviceProviderId,
      payload,
      onResponse,
      onError
    );
  };

  return (
    <div className="mp-page-padding">
      {console.log("pr- providerToCreate=", providerToCreate)}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="mp-two-side-view">
            <div className="mp-heading-with-back-button">
              <div
                onClick={() => {
                  onExit();
                }}
              >
                <FontIcon
                  style={{
                    fontSize: "28px",
                    paddingTop: "2px",
                    paddingRight: "12px",
                    cursor: "pointer",
                  }}
                >
                  arrow_back
                </FontIcon>
              </div>
              <h2>
                {mode == 1
                  ? `Create new service provider`
                  : mode == 2
                  ? `View service provider`
                  : mode == 3
                  ? `Update service provider`
                  : ``}
              </h2>
            </div>
            <div className="mp-buttons-container">
              <div className="mp-buttons-right-alignment">
                {mode != 2 && (
                  <Button
                    raised
                    disabled={loading}
                    className={
                      loading
                        ? "mp-button-save-disabled"
                        : "mp-button-save-enabled"
                    }
                    onClick={() => {
                      verifyData();
                    }}
                  >
                    {mode == 1 ? "Save" : "Update"}
                  </Button>
                )}

                <Button
                  raised
                  disabled={loading}
                  className="mp-button-cancel"
                  onClick={() => {
                    onExit();
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <h3 className="mp-sub-heading-margin">{`Service category`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Service type`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              <SelectField
                id={"mp-select-service-type"}
                disabled={mode == 2 || mode == 3}
                placeholder="Select"
                fullWidth={true}
                className="mp-select-field"
                menuItems={servicesTypeObj}
                value={
                  providerToCreate.serviceTypeId != null
                    ? providerToCreate.serviceTypeId
                    : ""
                }
                onChange={(e) => {
                  let selectedService = servicesTypeArr.find(
                    (ser) => e == ser.id
                  );

                  if (selectedService.id == 1) {
                    setProviderToCreate({
                      ...providerToCreate,
                      serviceTypeId: e,
                      checkupInfo: {},
                      consultationInfo: {
                        identityDetails: {},
                        contactDetails: {},
                        address: {},
                        reviewData: {},
                        availability: {
                          config: {
                            slotDurationInMinutes: 0,
                            bookingPerSlot: 0,
                            minBookingDays: 0,
                            maxAdvBookingDays: 0,
                          },
                          schedule: {
                            monday: [],
                            tuesday: [],
                            wednesday: [],
                            thursday: [],
                            friday: [],
                            saturday: [],
                            sunday: [],
                          },
                          unavailableTime: [],
                          availableTime: [],
                        },
                      },
                    });
                    setServiceProviderView(1);
                  } else if (selectedService.id == 2) {
                    setProviderToCreate({
                      ...providerToCreate,
                      serviceTypeId: e,
                      consultationInfo: {},
                      checkupInfo: {
                        identityDetails: {},
                        contactDetails: {},
                        address: {},
                        reviewData: {},
                        vendorGroup: {},
                        availability: {
                          config: {
                            slotDurationInMinutes: 0,
                            bookingPerSlot: 0,
                            minBookingDays: 0,
                            maxAdvBookingDays: 0,
                          },
                          schedule: {
                            monday: [],
                            tuesday: [],
                            wednesday: [],
                            thursday: [],
                            friday: [],
                            saturday: [],
                            sunday: [],
                          },
                          unavailableTime: [],
                          availableTime: [],
                        },
                      },
                    });
                    setServiceProviderView(2);
                  }
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      {serviceProviderView == 1 && countries != null && (
        <ServiceProviderConsultation
          providerToCreate={providerToCreate}
          setProviderToCreate={setProviderToCreate}
          mode={mode}
          countries={countries}
          calendarHeadings={calendarHeadings}
        />
      )}
      {serviceProviderView == 2 && countries != null && (
        <ServiceProviderHealthCheckup
          providerToCreate={providerToCreate}
          setProviderToCreate={setProviderToCreate}
          mode={mode}
          countries={countries}
          calendarHeadings={calendarHeadings}
        />
      )}
    </div>
  );
};

export default CreateServiceProvider;
