import React, { useEffect, useState } from "react";
import FontIcon from "react-md/lib/FontIcons";
import "../styles/expandableTable.css";
import NoRecordsIcon from "../../../../../assets/icons/no-records.png";
import { Button } from "react-md";
import {
  TextField,
  MenuItem,
  Menu,
  IconButton,
  Radio,
} from "@material-ui/core";

const ExpandableTable = (props) => {
  const [expandedRowId, setExpandedRowId] = useState(null); // state to track expanded row
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuFor, setMenuFor] = useState({});
  const [tableData, setTableData] = useState(
    props?.data?.assignedServiceProviders
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("props recvd in expandabel table: ", props);
  }, []);

  useEffect(() => {
    setTableData(props?.data?.assignedServiceProviders);
    console.log("props recvd in expandabel table: ", props);
  }, [props]);

  // useEffect(() => {
  //     // props.getAssignedServiceProvidersDataByFilter(
  //     //     {
  //     //         pageNum: pageNum,
  //     //         pageSize: pageSize,
  //     //         serviceIds: [props?.data?.service?.id ? props?.data?.service?.id?.toString() : ""],
  //     //         serviceProviderIds: []
  //     //     }
  //     // )
  // }, [pageNum]);

  const handleRowClick = (id) => {
    setExpandedRowId(expandedRowId === id ? null : id); // toggle expansion
  };
  const handleMoreClick = (event, serviceProvider) => {
    setAnchorEl(event.currentTarget);
    setMenuFor(serviceProvider);
  };
  // const handlePrevNextAssignedServiceProvider = (arg) => {
  //     if (arg) {
  //         props.getAssignedServiceProvidersDataByFilter(
  //             {
  //                 pageNum: pageNum+1,
  //                 pageSize: pageSize,
  //                 serviceIds: [props?.data?.service?.id ? props?.data?.service?.id?.toString() : ""],
  //                 serviceProviderIds: []
  //             }
  //         )
  //         setPageNum((prevPage) => prevPage + 1);
  //     } else {
  //         if (pageNum > 1) {
  //             setPageNum((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
  //         }
  //     }
  // };

  const handleEditServiceProviders = (ele, opt) => {
    console.log("ni ,  In expanadable tile  -> ", ele);
    if (opt == 1) {
      props.setView(6, {
        mode: "view",
        service: props?.data?.service,
        serviceProvider: ele,
      });
    } else if (opt == 2) {
      props.setView(6, {
        mode: "update",
        service: props?.data?.service,
        serviceProvider: ele,
      });
    }
  };

  return (
    <div className="table-container">
      <table className="expandable-table">
        <thead>
          <tr>
            <th>Service provider ID</th>
            <th>Name</th>
            <th>Mode</th>
            <th>Cost price (Rs)</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="animated-box">
          {Boolean(tableData?.length == null || tableData?.length <= 0) && (
            <tr>
              <td colSpan={5}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 40,
                  }}
                >
                  <div
                    style={{ textAlign: "center", fontFamily: "Montserrat" }}
                  >
                    No service provider is assigned to this service yet.
                  </div>
                  <div style={{ height: "16px" }}></div>
                  <img src={NoRecordsIcon} width={50} height={62} />
                </div>
              </td>
            </tr>
          )}

          {tableData && tableData.length > 0 && (
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td
                colSpan={3}
                align="right"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Button
                  onClick={() => {
                    props.goToPrevPage();
                  }}
                  disabled={props?.pageNum <= 1 || loading}
                >
                  <FontIcon className="navigo-count-icon">arrow_left</FontIcon>
                </Button>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  Showing&nbsp;
                  <span
                    style={{
                      color: "#ef7c01",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {(props?.pageNum - 1) * props?.pageSize + 1}&nbsp;
                  </span>
                  <span
                    style={{
                      color: "#ef7c01",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    - &nbsp;
                    {props?.totalCount > props?.pageNum * props?.pageSize
                      ? props?.pageNum * props?.pageSize
                      : props?.totalCount}
                  </span>
                  <span>&nbsp;&nbsp;of&nbsp;</span>
                  <span>{` ${props?.totalCount}`}</span>
                </div>
                <Button
                  onClick={() => {
                    props.goToNextPage();
                  }}
                  disabled={
                    loading ||
                    props?.pageNum * props?.pageSize > props?.totalCount ||
                    (props?.totalCount > props?.pageNum * props?.pageSize
                      ? props?.pageNum * props?.pageSize
                      : props?.totalCount) == props?.totalCount
                  }
                >
                  <FontIcon>arrow_right</FontIcon>
                </Button>
              </td>
            </tr>
          )}

          {tableData.map((row, index) => (
            <React.Fragment key={index}>
              <tr
                // onClick={() => handleRowClick(row?.serviceProviderId)}
                className={`${index > 0 ? "table-row-border" : ""}`}
              >
                <td>{row?.serviceProviderId}</td>
                <td>{row?.serviceProviderName}</td>
                <td>{row?.serviceModes?.[0]?.mode}</td>
                <td>{row?.serviceModes?.[0]?.price}</td>
                <td>
                  {row?.serviceModes?.[0]?.modeStatus != null && (
                    <div
                      className={`${
                        row?.serviceModes?.[0]?.modeStatus
                          ? "navigo-appoint-non-select-status-active"
                          : "navigo-appoint-non-select-status-inactive"
                      } `}
                    >
                      <span>
                        {row?.serviceModes?.[0]?.modeStatus
                          ? "Active"
                          : "Inactive"}
                      </span>
                    </div>
                  )}
                </td>
                <td />
                <td>
                  <FontIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleMoreClick(e, row)}
                  >
                    more_vert
                  </FontIcon>
                </td>
              </tr>
              {row?.serviceModes?.slice(1)?.map((row, index) => (
                <tr key={index} className="table-row-expanded animated-box">
                  <td></td>
                  <td></td>
                  <td>{row?.mode}</td>
                  <td>{row?.price}</td>
                  <td>
                    {row?.modeStatus != null && (
                      <div
                        className={`${
                          row?.modeStatus
                            ? "navigo-appoint-non-select-status-active"
                            : "navigo-appoint-non-select-status-inactive"
                        } `}
                      >
                        <span>{row?.modeStatus ? "Active" : "Inactive"}</span>
                      </div>
                    )}
                    <td />
                  </td>
                </tr>
              ))}

              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                  setMenuFor(null);
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleEditServiceProviders(menuFor, 2);
                    setAnchorEl(null);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleEditServiceProviders(menuFor, 1);
                    setAnchorEl(null);
                  }}
                >
                  View
                </MenuItem>
              </Menu>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExpandableTable;
