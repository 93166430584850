import React from "react";

import "./styles/index.css";
import Helper from "util/Helper";
import ServiceModuleHome from "./components/ServiceModuleHome";
import ServiceDashboard from "./components/ServiceDashboard";
import ServiceProviderDashboard from "./components/ServiceProviderDashboard";
import CreateService from "./components/CreateService";
import AssignedServiceProvider from "./components/AssignedServiceProvider";
import CreateServiceProvider from "./components/CreateServiceProvider";
import ServiceProviderActionScreen from "./components/ServiceProviderActionScreen";
import AvailabilityCalendar from "./helper/AvailabilityCalendar";

class ServiceModule extends React.Component {
  constructor(props) {
    super(props);
    this.helper = new Helper();
    this.state = {
      view: 0,
      props: null,
    };
  }

  setView = (v, p) => {
    this.setState({ view: v, props: p });
  };

  render() {
    return (
      <>
        {/* {<div>{`view = ${this.state.view}`}</div>} */}
        {this.state.view == 0 ? (
          <ServiceModuleHome setView={this.setView} data={this.state.props} />
        ) : this.state.view == 1 ? (
          <ServiceDashboard setView={this.setView} data={this.state.props} />
        ) : this.state.view == 2 ? (
          <CreateService setView={this.setView} data={this.state.props} />
        ) : this.state.view == 3 ? (
          <AssignedServiceProvider
            setView={this.setView}
            data={this.state.props}
          />
        ) : this.state.view == 4 ? (
          <ServiceProviderDashboard
            setView={this.setView}
            data={this.state.props}
          />
        ) : this.state.view == 5 ? (
          <CreateServiceProvider
            setView={this.setView}
            data={this.state.props}
          />
        ) : this.state.view == 6 ? (
          <ServiceProviderActionScreen
            setView={this.setView}
            data={this.state.props}
          />
        ) : (
          <div>nothing to display</div>
        )}
      </>
    );
  }
}

export default ServiceModule;
