import React, { useState } from "react";
import { Button, DialogContainer, SelectField, TextField } from "react-md";
import "../styles/availabilityCalendarStyle.css";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FontIcon from "react-md/lib/FontIcons";
import { Checkbox } from "@material-ui/core";
import EventService from "../../../../../service/event/EventService";
import moment from "moment";

const CalendarAddOns = ({
  heading,
  addOnDialogMode,
  closeCalendarAddOns,
  dayData,
  availability,
  setAvailability,
  type,
  addOnindex,
  leaveDatesArr,
  availabilityDatesArr,
}) => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [applyForFullDay, setApplyForFullDay] = useState(false);
  const [slots, setSlots] = useState([]);

  useState(() => {
    if (addOnDialogMode == 1) {
      setDateFrom(null);
      setDateTo(null);
      setApplyForFullDay(false);
      setSlots([
        {
          start: "",
          end: "",
          comment: "",
        },
      ]);
    } else if (addOnDialogMode == 3 || addOnDialogMode == 2) {
      setDateFrom(moment(dayData.date, "YYYY-MM-DD"));
      setDateTo(moment(dayData.date, "YYYY-MM-DD"));
      if (
        dayData.slots.length == 1 &&
        dayData.slots[0].start == "00:00" &&
        dayData.slots[0].end == "23:59"
      ) {
        setApplyForFullDay(true);
        setSlots([
          {
            start: "00:00",
            end: "23:59",
            comment: dayData.slots[0].comment,
          },
        ]);
      } else {
        setApplyForFullDay(false);
        let sl = dayData.slots.map((s) => {
          return { start: s.start, end: s.end, comment: s.comment };
        });
        setSlots(sl);
      }
    }
  }, []);

  const exitDialog = () => {
    setDateFrom(null);
    setDateTo(null);
    setApplyForFullDay(false);
    setSlots([]);
    closeCalendarAddOns();
  };

  const TIME_OBJECT_ITEMS = [
    { label: "12:00 AM", value: "00:00" },
    { label: "12:30 AM", value: "00:30" },
    { label: "01:00 AM", value: "01:00" },
    { label: "01:30 AM", value: "01:30" },
    { label: "02:00 AM", value: "02:00" },
    { label: "02:30 AM", value: "02:30" },
    { label: "03:00 AM", value: "03:00" },
    { label: "03:30 AM", value: "03:30" },
    { label: "04:00 AM", value: "04:00" },
    { label: "04:30 AM", value: "04:30" },
    { label: "05:00 AM", value: "05:00" },
    { label: "05:30 AM", value: "05:30" },
    { label: "06:00 AM", value: "06:00" },
    { label: "06:30 AM", value: "06:30" },
    { label: "07:00 AM", value: "07:00" },
    { label: "07:30 AM", value: "07:30" },
    { label: "08:00 AM", value: "08:00" },
    { label: "08:30 AM", value: "08:30" },
    { label: "09:00 AM", value: "09:00" },
    { label: "09:30 AM", value: "09:30" },
    { label: "10:00 AM", value: "10:00" },
    { label: "10:30 AM", value: "10:30" },
    { label: "11:00 AM", value: "11:00" },
    { label: "11:30 AM", value: "11:30" },
    { label: "12:00 PM", value: "12:00" },
    { label: "12:30 PM", value: "12:30" },
    { label: "01:00 PM", value: "13:00" },
    { label: "01:30 PM", value: "13:30" },
    { label: "02:00 PM", value: "14:00" },
    { label: "02:30 PM", value: "14:30" },
    { label: "03:00 PM", value: "15:00" },
    { label: "03:30 PM", value: "15:30" },
    { label: "04:00 PM", value: "16:00" },
    { label: "04:30 PM", value: "16:30" },
    { label: "05:00 PM", value: "17:00" },
    { label: "05:30 PM", value: "17:30" },
    { label: "06:00 PM", value: "18:00" },
    { label: "06:30 PM", value: "18:30" },
    { label: "07:00 PM", value: "19:00" },
    { label: "07:30 PM", value: "19:30" },
    { label: "08:00 PM", value: "20:00" },
    { label: "08:30 PM", value: "20:30" },
    { label: "09:00 PM", value: "21:00" },
    { label: "09:30 PM", value: "21:30" },
    { label: "10:00 PM", value: "22:00" },
    { label: "10:30 PM", value: "22:30" },
    { label: "11:00 PM", value: "23:00" },
    { label: "11:30 PM", value: "23:30" },
    { label: "11:59 PM", value: "23:59" },
  ];

  const handleStartTimeChange = (index, value) => {
    let newSlots = slots.map((slot, i) =>
      i === index ? { ...slot, start: value, end: "" } : slot
    );
    // Keep only slots from index 0 to the given index
    newSlots = newSlots.slice(0, index + 1);
    setSlots(newSlots);
  };
  const handleEndTimeChange = (index, value) => {
    let newSlots = slots.map((slot, i) =>
      i === index ? { ...slot, end: value } : slot
    );
    // Keep only slots from index 0 to the given index
    newSlots = newSlots.slice(0, index + 1);
    setSlots(newSlots);
  };

  const handleCommentChange = (index, value) => {
    const newSlots = slots.map((slot, i) =>
      i === index ? { ...slot, comment: value } : slot
    );
    setSlots(newSlots);
  };
  const handleSave = () => {
    console.log(dateFrom?.format("YYYY-MM-DD"));
    console.log(dateTo?.format("YYYY-MM-DD"));
    console.log(applyForFullDay);
    console.log(slots);
    isValid();
  };
  const isValid = () => {
    if (dateFrom?.format("YYYY-MM-DD") == undefined) {
      EventService.emit("showError", {
        message: "Please select start date",
      });
    } else if (dateTo?.format("YYYY-MM-DD") == undefined) {
      EventService.emit("showError", {
        message: "Please select end date",
      });
    } else if (isSlotNotFilled()) {
      EventService.emit("showError", {
        message: "Please fill all slot times",
      });
    } else {
      let day1 = moment(dateFrom?.format("YYYY-MM-DD"));
      let day2 = moment(dateTo?.format("YYYY-MM-DD"));
      let daysDiff = day2.diff(day1, "days") + 1;
      let schedule = [];
      let newSlots = slots.map((slot) => {
        return { ...slot };
      });
      for (let i = 0; i < daysDiff; i++) {
        let obj = {
          date: day1.clone().add(i, "days").format("YYYY-MM-DD"),
          slots: newSlots,
        };
        schedule = [...schedule, obj];
      }
      if (type == 1 && addOnDialogMode == 1) {
        setAvailability({
          ...availability,
          unavailableTime: [...availability.unavailableTime, ...schedule].sort(
            (a, b) => moment(a.date).diff(moment(b.date))
          ),
        });
      } else if (type == 1 && addOnDialogMode == 3) {
        let newUnavailableTime = availability.unavailableTime.filter(
          (_, i) => i !== addOnindex
        );

        setAvailability({
          ...availability,
          unavailableTime: [...newUnavailableTime, ...schedule].sort((a, b) =>
            moment(a.date).diff(moment(b.date))
          ),
        });
      } else if (type == 2 && addOnDialogMode == 1) {
        setAvailability({
          ...availability,
          availableTime: [...availability.availableTime, ...schedule].sort(
            (a, b) => moment(a.date).diff(moment(b.date))
          ),
        });
      } else if (type == 2 && addOnDialogMode == 3) {
        let newAvailableTime = availability.availableTime.filter(
          (_, i) => i !== addOnindex
        );

        setAvailability({
          ...availability,
          availableTime: [...newAvailableTime, ...schedule].sort((a, b) =>
            moment(a.date).diff(moment(b.date))
          ),
        });
      }
      exitDialog();
    }
  };
  const isSlotNotFilled = () => {
    let result = false;
    slots.forEach((slot) => {
      if (slot.start == "" || slot.end == "") {
        result = true;
      }
    });
    return result;
  };

  return (
    <div>
      <DialogContainer
        id="calendar-add-on"
        visible={true}
        onHide={() => {
          exitDialog();
        }}
        height={"350px"}
        width={"900px"}
        focusOnMount={false}
        className="calendar-add-on-container"
        style={{ textAlign: "left" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h4>{heading}</h4>
          </div>
          <div>
            {addOnDialogMode != 2 && (
              <Button
                raised
                className={"btn-enabled"}
                onClick={() => {
                  handleSave();
                }}
              >
                {addOnDialogMode == 3 ? "Update" : "Save"}
              </Button>
            )}
            <Button
              raised
              className={"btn-disabled"}
              style={{ marginLeft: "15px" }}
              onClick={() => {
                exitDialog();
              }}
            >
              {"Cancel"}
            </Button>
          </div>
        </div>
        <br />
        <div style={{ display: "flex" }}>
          <div className="calendar-field-container">
            <div className="calendar-label-text">{`Date`}</div>

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                value={dateFrom ? dateFrom : null}
                onChange={(date) => {
                  setDateFrom(date);
                  if (addOnDialogMode == 3) {
                    setDateTo(date);
                  }
                }}
                autoOk={true}
                className="leave-datepicker"
                format="DD MMM YYYY"
                placeholder="Choose a date"
                keyboardIcon={<FontIcon>calendar_month</FontIcon>}
                variant="inline"
                minDate={addOnDialogMode == 2 ? undefined : moment()}
                maxDate={
                  addOnDialogMode == 3 ? undefined : dateTo ? dateTo : undefined
                }
                disabled={addOnDialogMode == 2}
                shouldDisableDate={(date) =>
                  type == 1
                    ? leaveDatesArr.includes(moment(date).format("YYYY-MM-DD"))
                    : type == 2
                    ? availabilityDatesArr.includes(
                        moment(date).format("YYYY-MM-DD")
                      )
                    : false
                }
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="separate-fields-to">To</div>
          <div className="calendar-field-container">
            <div className="calendar-label-text">{`Date`}</div>

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                value={dateTo ? dateTo : null}
                onChange={(date) => {
                  setDateTo(date);
                }}
                autoOk={true}
                className="leave-datepicker"
                format="DD MMM YYYY"
                placeholder="Choose a date"
                keyboardIcon={<FontIcon>calendar_month</FontIcon>}
                variant="inline"
                minDate={
                  addOnDialogMode == 2
                    ? undefined
                    : dateFrom
                    ? dateFrom
                    : moment()
                }
                maxDate={
                  dateFrom
                    ? type == 1
                      ? leaveDatesArr
                          .filter(
                            (date) =>
                              date > moment(dateFrom).format("YYYY-MM-DD")
                          ) // Get dates greater than dateFrom
                          .sort()[0] || undefined
                      : type == 2
                      ? availabilityDatesArr
                          .filter(
                            (date) =>
                              date > moment(dateFrom).format("YYYY-MM-DD")
                          ) // Get dates greater than dateFrom
                          .sort()[0] || undefined
                      : undefined
                    : undefined
                }
                disabled={
                  addOnDialogMode == 3 ||
                  addOnDialogMode == 2 ||
                  (addOnDialogMode == 1 && !dateFrom)
                }
                shouldDisableDate={(date) =>
                  type == 1
                    ? leaveDatesArr.includes(moment(date).format("YYYY-MM-DD"))
                    : type == 2
                    ? availabilityDatesArr.includes(
                        moment(date).format("YYYY-MM-DD")
                      )
                    : false
                }
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "10px 0px 10px -10px",
          }}
        >
          <Checkbox
            id="fullday-checkbox"
            name="leaves-fullday-checkbox"
            checked={applyForFullDay}
            color={addOnDialogMode == 2 ? "default" : "secondary"}
            disabled={addOnDialogMode == 2}
            onChange={(value, proxy) => {
              if (applyForFullDay) {
                setSlots([
                  {
                    start: "",
                    end: "",
                    comment: "",
                  },
                ]);
              } else {
                setSlots([
                  {
                    start: "00:00",
                    end: "23:59",
                    comment: "",
                  },
                ]);
              }
              setApplyForFullDay(!applyForFullDay);
            }}
          />
          <div>Apply for full day</div>
        </div>
        {slots.map((slot, index) => {
          return (
            <>
              <div className="time-addon">
                <div className="time-container">
                  <div className="time-text">Start time</div>
                  <SelectField
                    id="time-select-field"
                    placeholder="Select"
                    disabled={applyForFullDay || addOnDialogMode == 2}
                    className="select-time"
                    value={slot.start}
                    menuItems={
                      index == 0
                        ? TIME_OBJECT_ITEMS.filter(
                            (item) => item.value != "23:59"
                          )
                        : TIME_OBJECT_ITEMS.filter((item) => {
                            if (
                              item.value > slots[index - 1].end &&
                              item.value != "23:59"
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          })
                    }
                    style={{
                      position: "static",
                      maxWidth: "150px",
                    }}
                    onChange={(value) => handleStartTimeChange(index, value)}
                  />
                </div>
                <div className="time-container">
                  <div className="time-text">End time</div>
                  <SelectField
                    id="time-select-field"
                    placeholder="Select"
                    disabled={
                      applyForFullDay ||
                      addOnDialogMode == 2 ||
                      slot.start == ""
                    }
                    className="select-time"
                    value={slot.end}
                    menuItems={TIME_OBJECT_ITEMS.filter(
                      (item) => item.value > slot.start
                    )}
                    style={{ position: "static", maxWidth: "150px" }}
                    onChange={(value) => handleEndTimeChange(index, value)}
                  />
                </div>
                <div className="calendar-field-container-comment">
                  <div className="calendar-label-text">{`Remarks`}</div>

                  <TextField
                    id={"calendar-leave-remark"}
                    lineDirection="left"
                    placeholder="Type here"
                    value={slot.comment}
                    disabled={addOnDialogMode == 2}
                    onChange={(value) => handleCommentChange(index, value)}
                  />
                </div>
                {addOnDialogMode == 2 ? (
                  <></>
                ) : index > 0 ? (
                  <div
                    className="remove-slot"
                    onClick={() => {
                      let newSlots = slots.filter((_, i) => i !== index);
                      setSlots(newSlots);
                    }}
                  >
                    <FontIcon>close</FontIcon>
                  </div>
                ) : (
                  !applyForFullDay &&
                  slots.at(-1).end != "" &&
                  slots.at(-1).end != "23:30" &&
                  slots.at(-1).end != "23:59" && (
                    <div
                      className="add-more"
                      onClick={() => {
                        setSlots([
                          ...slots,
                          {
                            start: "",
                            end: "",
                            comment: "",
                          },
                        ]);
                      }}
                    >{`+ Add more slots`}</div>
                  )
                )}
              </div>
            </>
          );
        })}
      </DialogContainer>
    </div>
  );
};

export default CalendarAddOns;
