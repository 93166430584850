import React from "react";
import AccountDashboard from "./components/AccountDashboard";
import CreateAccount from "./components/CreateAccount";
import AssignedPlans from "./components/AssignedPlans";
import CreatePlan from "./components/CreatePlan";
import AssignServiceToPlan from "./components/AssignServiceToPlan";
import CustomiseServiceProvider from "./components/CustomiseServiceProvider";

class AccountModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 0,
      props: null,
      // view: 5,
      // props: {
      //   planId: 1,
      //   serviceId: 1,
      //   serviceProviderId: 1,
      //   serviceMode: "Video",
      // },
    };
  }

  setView = (v, p) => {
    this.setState({ view: v, props: p });
  };

  render() {
    return (
      <>
        {/* {<div>{`view = ${this.state.view}`}</div>} */}
        {this.state.view == 0 ? (
          <AccountDashboard setView={this.setView} data={this.state.props} />
        ) : this.state.view == 1 ? (
          <CreateAccount setView={this.setView} data={this.state.props} />
        ) : this.state.view == 2 ? (
          <AssignedPlans setView={this.setView} data={this.state.props} />
        ) : this.state.view == 3 ? (
          <CreatePlan setView={this.setView} data={this.state.props} />
        ) : this.state.view == 4 ? (
          <AssignServiceToPlan setView={this.setView} data={this.state.props} />
        ) : this.state.view == 5 ? (
          <CustomiseServiceProvider
            setView={this.setView}
            data={this.state.props}
          />
        ) : (
          // : this.state.view == 3 ? (
          //   <CreatePlan setView={this.setView} data={this.state.props} />
          // )

          <div>nothing to display</div>
        )}
      </>
    );
  }
}

export default AccountModule;
