import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";
import Helper from "util/Helper";

export class PlansUtils {
  constructor() {
    this.apiService = new APIService();
    this.helper = new Helper();
  }
  getAccountsById(id, onResponse, onError) {
    let url = APIConfig.myPlan.accountDashboard.getAccountById.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  getPlanByFilter(body, onResponse, onError) {
    let url = APIConfig.myPlan.assignedPlans.getAssignedPlansByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }

  getFamilyRelations(onResponse, onError) {
    let url = APIConfig.familyPlan.getAllRelation;
    return this.apiService.get(url, onResponse, onError);
  }

  getPlansByFilter(id, onResponse, onError) {
    let url = APIConfig.familyPlan.getAllRelation;
    return this.apiService.get(url, onResponse, onError);
  }
  uploadImg = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.uploadContent;
    return this.apiService.postWithFormData(url, body, onResponse, onError);
  };
  createPlan = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.plan.create;
    return this.apiService.post(url, body, onResponse, onError);
  };
  updatePlan = (body, id, onResponse, onError) => {
    let url = APIConfig.myPlan.plan.update.format(id);
    return this.apiService.put(url, body, onResponse, onError);
  };

  getAssignedServicesToPlanByFilter = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.plan.getAssignedServicesToPlanByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  };
  getProviderOfPlanService = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.plan.getProviderOfPlanService;
    return this.apiService.post(url, body, onResponse, onError);
  };
  getServicesByFilter(body, onResponse, onError) {
    let url = APIConfig.myPlan.serviceDashboard.fetchServicesByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }
  createCustomisedServiceProvider = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.plan.customiseServiceProvider;
    return this.apiService.post(url, body, onResponse, onError);
  };
  updateCustomisedServiceProvider = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.plan.customiseServiceProvider;
    return this.apiService.put(url, body, onResponse, onError);
  };
  getAccountsByFilter(body, onResponse, onError) {
    let url = APIConfig.myPlan.accountDashboard.getAccountsByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }
}

export default PlansUtils;
