import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";
import Helper from "util/Helper";

export class ServiceProviderDashboardUtils {
  constructor() {
    this.apiService = new APIService();
    this.helper = new Helper();
  }
  getServiceProvidersByFilter(body, onResponse, onError) {
    let url =
      APIConfig.myPlan.serviceProviderDashboard.fetchServiceProvidersByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }
  getServiceType(body, onResponse, onError) {
    let url = APIConfig.myPlan.createService.getServiceType.format(body);
    return this.apiService.get(url, onResponse, onError);
  }
  getServiceSubType(id, status, onResponse, onError) {
    let url = APIConfig.myPlan.createService.getServiceSubType.format(
      id,
      status
    );

    return this.apiService.get(url, onResponse, onError);
  }
}

export default ServiceProviderDashboardUtils;
