import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, TextField, Checkbox } from "react-md";
import UploadFileIcon from "../../../../../assets/icons/upload-file-icon.png";
import "../styles/createAccountStyles.css";
import FontIcon from "react-md/lib/FontIcons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AccountDashboardUtils from "../services/AccountDashboardUtils";
import EventService from "../../../../../service/event/EventService";
import PlansUtils from "../services/PlansUtils";
import AvailabilityCalendar from "../../service-module/helper/AvailabilityCalendar";
import AuthenticationService from "../../../../../service/auth/AuthenticationService";

export const CustomiseServiceProvider = (props) => {
  const [fetchedPlan, setFetchedPlan] = useState(null);
  const [fetchedService, setFetchedService] = useState(null);
  const [fetchedServiceProvider, setFetchedServiceProvider] = useState(null);
  const [fetchedAccount, setFetchedAccount] = useState(null);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [customisedSP, setCustomisedSP] = useState(null);
  const [loading, setLoading] = useState(false);
  const plansUtils = new PlansUtils();
  const authenticationService = new AuthenticationService();
  useEffect(() => {
    getPlanByFilter();
    getServicesByFilter();
    getProviderOfPlanService();
  }, []);

  useEffect(() => {
    if (
      fetchedPlan != null &&
      fetchedService != null &&
      fetchedServiceProvider != null &&
      fetchedAccount != null
    ) {
      setIsDataFetched(true);
      console.log(
        "pr- got all data=",
        fetchedPlan,
        fetchedService,
        fetchedServiceProvider,
        fetchedAccount
      );
      let obj = {
        planId: fetchedPlan?.id,
        serviceId: fetchedService?.id,
        providerId: fetchedServiceProvider?.serviceProviderId,
        createdBy: authenticationService.getUserProfile().employeeProfile?.id,
        mode: fetchedServiceProvider.serviceModes[0].mode,
        isActive:
          fetchedServiceProvider.serviceModes[0].isActive == true
            ? "true"
            : "false",
        providerAvailability:
          fetchedServiceProvider.serviceModes[0].availability,
      };
      console.log("pr- obh in start =", obj);
      setCustomisedSP(obj);
    }
  }, [fetchedPlan, fetchedService, fetchedServiceProvider, fetchedAccount]);

  const exitPage = () => {
    props.setView(4, {
      mode: "view",
      editMode: "service-providers",
      planId: props?.data?.planId,
      serviceId: props?.data?.serviceId,
    });
  };

  const getAccountByAccountCode = (code) => {
    let body = {
      pageNum: 1,
      pageSize: 1,
      accountCode: code,
    };
    const onResponse = (res) => {
      console.log("pr- acc code res=", res);
      setFetchedAccount(res.accounts[0]);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    plansUtils.getAccountsByFilter(body, onResponse, onError);
  };

  const getPlanByFilter = () => {
    let body = {
      pageNum: 1,
      pageSize: 1,
      planIds: [props?.data?.planId],
    };
    const onResponse = (res) => {
      if (res.plans.length != 0) {
        setFetchedPlan(res.plans[0]);
        if (res.plans[0].accountCode != "") {
          getAccountByAccountCode(res.plans[0].accountCode);
        }
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    plansUtils.getPlanByFilter(body, onResponse, onError);
  };

  const getServicesByFilter = () => {
    let body = {
      pageNum: 1,
      pageSize: 1,
      Ids: [props?.data?.serviceId],
    };
    const onResponse = (res) => {
      if (res.services.length != 0) {
        setFetchedService(res.services[0]);
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    plansUtils.getServicesByFilter(body, onResponse, onError);
  };
  const getProviderOfPlanService = () => {
    let body = {
      pageNum: 1,
      pageSize: 2,
      serviceIds: [props?.data?.serviceId],
      serviceProviderIds: [props?.data?.serviceProviderId],
      planId: props?.data?.planId,
    };
    const onResponse = (res) => {
      if (res.providers.length != 0) {
        let provider = res.providers[0];
        provider = {
          ...provider,
          serviceModes: provider.serviceModes.filter(
            (e) => e.mode == props?.data?.serviceMode
          ),
        };
        setFetchedServiceProvider(provider);
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    plansUtils.getProviderOfPlanService(body, onResponse, onError);
  };
  const setAvailability = (availability) => {
    setCustomisedSP({
      ...customisedSP,
      providerAvailability: { ...availability },
    });
  };

  const handleSave = () => {
    if (isDataValid()) {
      let body = {
        ...customisedSP,
        isActive: customisedSP.isActive == "true" ? true : false,
      };
      console.log("pr- valid data=", body);

      if (fetchedServiceProvider.serviceModes[0].isCustomized) {
        updateCustomisedServiceProvider(body);
      } else {
        createCustomisedServiceProvider(body);
      }
    }
  };
  const createCustomisedServiceProvider = (body) => {
    setLoading(true);
    const onResponse = (res) => {
      setLoading(false);
      EventService.emit("showError", {
        message: `Service provider customised successfully`,
      });
      exitPage();
    };
    const onError = (err) => {
      setLoading(false);
      EventService.emit("showError", {
        message: `Error 1: ${err?.message}`,
      });
    };
    plansUtils.createCustomisedServiceProvider(body, onResponse, onError);
  };
  const updateCustomisedServiceProvider = (body) => {
    setLoading(true);
    const onResponse = (res) => {
      setLoading(false);
      EventService.emit("showError", {
        message: `Service provider customised successfully`,
      });
      exitPage();
    };
    const onError = (err) => {
      setLoading(false);
      EventService.emit("showError", {
        message: `Error 2: ${err?.message}`,
      });
    };
    plansUtils.updateCustomisedServiceProvider(body, onResponse, onError);
  };
  const isDataValid = () => {
    const hasEmptySlot = Object.keys(
      customisedSP.providerAvailability.schedule
    ).some((day) =>
      customisedSP.providerAvailability.schedule[day].some(
        (slot) => slot.start === "" || slot.end === ""
      )
    );
    if (hasEmptySlot) {
      EventService.emit("showError", {
        message: "Please fill all the slots in the schedule",
      });
      return false;
    } else if (
      customisedSP.providerAvailability.config.slotDurationInMinutes == null ||
      customisedSP.providerAvailability.config.slotDurationInMinutes == "" ||
      !(
        Number.isInteger(
          customisedSP.providerAvailability.config.slotDurationInMinutes
        ) && customisedSP.providerAvailability.config.slotDurationInMinutes > 0
      )
    ) {
      EventService.emit("showError", {
        message: "Please fill duration (mins)",
      });
      return false;
    } else if (
      customisedSP.providerAvailability.config.bookingPerSlot == null ||
      customisedSP.providerAvailability.config.bookingPerSlot == "" ||
      !(
        Number.isInteger(
          customisedSP.providerAvailability.config.bookingPerSlot
        ) && customisedSP.providerAvailability.config.bookingPerSlot > 0
      )
    ) {
      EventService.emit("showError", {
        message: "Please fill booking per slot ",
      });
      return false;
    } else if (
      customisedSP.providerAvailability.config.minBookingDays == null ||
      customisedSP.providerAvailability.config.minBookingDays == "" ||
      !(
        Number.isInteger(
          customisedSP.providerAvailability.config.minBookingDays
        ) && customisedSP.providerAvailability.config.minBookingDays > 0
      )
    ) {
      EventService.emit("showError", {
        message: "Please fill min booking notice (days)",
      });
      return false;
    } else if (
      customisedSP.providerAvailability.config.minBookingDays == null ||
      customisedSP.providerAvailability.config.minBookingDays == "" ||
      !(
        customisedSP.providerAvailability.config.minBookingDays >=
        fetchedServiceProvider.serviceModes[0].masterAvailability.config
          .minBookingDays
      )
    ) {
      EventService.emit("showError", {
        message:
          "Min booking notice (days) can't be lower than the minimum value",
      });
      return false;
    } else if (
      customisedSP.providerAvailability.config.maxAdvBookingDays == null ||
      customisedSP.providerAvailability.config.maxAdvBookingDays == "" ||
      !(
        Number.isInteger(
          customisedSP.providerAvailability.config.maxAdvBookingDays
        ) && customisedSP.providerAvailability.config.maxAdvBookingDays > 0
      )
    ) {
      EventService.emit("showError", {
        message: "Please fill max advance booking (days)",
      });
      return false;
    } else {
      return true;
    }
  };
  let STATUS_OPTIONS = [
    {
      label: "Active",
      value: "true",
    },
    {
      label: "Inactive",
      value: "false",
    },
  ];
  return (
    <div className="account-module-page-padding">
      {console.log("pr- csp=", customisedSP)}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="account-module-view">
            <div className="account-module-heading-with-back-button">
              <div
                className="account-module-back-btn"
                onClick={() => {
                  exitPage();
                }}
              >
                <FontIcon style={{ fontSize: "33px" }}>arrow_back</FontIcon>
              </div>
              <h2>{`Customise the service provider`}</h2>
            </div>
            <div className="account-module-buttons-container">
              <div className="account-module-buttons-right-alignment">
                <Button
                  raised
                  disabled={loading}
                  className={
                    loading
                      ? "account-module-button-save-disabled"
                      : "account-module-button-save-enabled"
                  }
                  onClick={() => {
                    handleSave();
                  }}
                >
                  {"Save"}
                </Button>

                <Button
                  raised
                  disabled={loading}
                  className="account-module-button-cancel"
                  onClick={() => {
                    exitPage();
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <br />

      <br />
      {isDataFetched && (
        <div>
          <div className="mp-plan-grey-heading">Plan details</div>
          <br />

          <div className="account-module-create-service-container">
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Plan ID`}</div>
                  <div>
                    <TextField
                      value={fetchedPlan?.id ?? ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Plan name`}</div>
                  <div>
                    <TextField
                      value={fetchedPlan?.basicDetails?.name ?? ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Account name`}</div>
                  <div>
                    <TextField
                      value={fetchedAccount.accountName ?? ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br />
          <div className="mp-plan-grey-heading">Service details</div>
          <br />

          <div className="account-module-create-service-container">
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Service type`}</div>
                  <div>
                    <TextField
                      value={fetchedService?.serviceTypeName ?? ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Service Id`}</div>
                  <div>
                    <TextField
                      value={fetchedService?.id ?? ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Service status on plan`}</div>
                  <div>
                    <TextField
                      value={
                        fetchedServiceProvider.serviceModes[0].isActive !=
                        undefined
                          ? fetchedServiceProvider.serviceModes[0].isActive ==
                            true
                            ? "Active"
                            : "Inactive"
                          : ""
                      }
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Service code`}</div>
                  <div>
                    <TextField
                      value={fetchedService?.name ?? ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Service name`}</div>
                  <div>
                    <TextField
                      value={fetchedService?.serviceName ?? ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br />
          <div className="mp-plan-grey-heading">Service provider</div>
          <br />

          <div className="account-module-create-service-container">
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Service provider ID`}</div>
                  <div>
                    <TextField
                      value={fetchedServiceProvider?.serviceProviderId ?? ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Service provider name`}</div>
                  <div>
                    <TextField
                      value={fetchedServiceProvider?.serviceProviderName ?? ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Service provider status`}</div>
                  <div>
                    <SelectField
                      placeholder="Select"
                      className="mp-plan-select-field"
                      menuItems={STATUS_OPTIONS}
                      value={
                        customisedSP.isActive != undefined
                          ? customisedSP.isActive
                          : ""
                      }
                      onChange={(e) => {
                        setCustomisedSP({ ...customisedSP, isActive: e });
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br />
          <br />
          <AvailabilityCalendar
            availability={customisedSP.providerAvailability}
            setAvailability={setAvailability}
            mode={1}
            headings={[
              "Customised weekly schedule of the provider for this service on this plan",
            ]}
            showOnlySchedule={true}
            slotsValidationSchedule={
              fetchedServiceProvider.serviceModes[0].masterAvailability.schedule
            }
          />
          <br />
          <div className="account-module-create-service-container">
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">
                    {`Duration (mins)`}
                    <label className="mp-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={
                        customisedSP.providerAvailability.config
                          .slotDurationInMinutes ?? ""
                      }
                      lineDirection="left"
                      helpOnFocus
                      helpText={
                        fetchedServiceProvider.serviceModes[0]
                          .masterAvailability.config.slotDurationInMinutes !=
                        undefined
                          ? `Value can't exceed ${fetchedServiceProvider.serviceModes[0].masterAvailability.config.slotDurationInMinutes}`
                          : false
                      }
                      onChange={(e) => {
                        if (
                          (/^\d+$/.test(e) || e === "") &&
                          Number(e) <=
                            fetchedServiceProvider.serviceModes[0]
                              .masterAvailability.config.slotDurationInMinutes
                        ) {
                          setCustomisedSP({
                            ...customisedSP,
                            providerAvailability: {
                              ...customisedSP.providerAvailability,
                              config: {
                                ...customisedSP.providerAvailability.config,
                                slotDurationInMinutes: Number(e),
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">
                    {`Booking per slot`}
                    <label className="mp-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={
                        customisedSP.providerAvailability.config
                          .bookingPerSlot ?? ""
                      }
                      lineDirection="left"
                      helpOnFocus
                      helpText={
                        fetchedServiceProvider.serviceModes[0]
                          .masterAvailability.config.bookingPerSlot != undefined
                          ? `Value can't exceed ${fetchedServiceProvider.serviceModes[0].masterAvailability.config.bookingPerSlot}`
                          : false
                      }
                      onChange={(e) => {
                        if (
                          (/^\d+$/.test(e) || e === "") &&
                          Number(e) <=
                            fetchedServiceProvider.serviceModes[0]
                              .masterAvailability.config.bookingPerSlot
                        ) {
                          setCustomisedSP({
                            ...customisedSP,
                            providerAvailability: {
                              ...customisedSP.providerAvailability,
                              config: {
                                ...customisedSP.providerAvailability.config,
                                bookingPerSlot: Number(e),
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">
                    {`Min booking notice (days)`}
                    <label className="mp-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={
                        customisedSP.providerAvailability.config
                          .minBookingDays ?? ""
                      }
                      lineDirection="left"
                      helpOnFocus
                      helpText={
                        fetchedServiceProvider.serviceModes[0]
                          .masterAvailability.config.minBookingDays != undefined
                          ? `Value can be minimum ${fetchedServiceProvider.serviceModes[0].masterAvailability.config.minBookingDays}`
                          : false
                      }
                      onChange={(e) => {
                        if (/^\d+$/.test(e) || e === "") {
                          setCustomisedSP({
                            ...customisedSP,
                            providerAvailability: {
                              ...customisedSP.providerAvailability,
                              config: {
                                ...customisedSP.providerAvailability.config,
                                minBookingDays: Number(e),
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">
                    {`Max advance booking (days)`}
                    <label className="mp-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={
                        customisedSP.providerAvailability.config
                          .maxAdvBookingDays ?? ""
                      }
                      lineDirection="left"
                      helpOnFocus
                      helpText={
                        fetchedServiceProvider.serviceModes[0]
                          .masterAvailability.config.maxAdvBookingDays !=
                        undefined
                          ? `Value can't exceed ${fetchedServiceProvider.serviceModes[0].masterAvailability.config.maxAdvBookingDays}`
                          : false
                      }
                      onChange={(e) => {
                        if (
                          (/^\d+$/.test(e) || e === "") &&
                          Number(e) <=
                            fetchedServiceProvider.serviceModes[0]
                              .masterAvailability.config.maxAdvBookingDays
                        ) {
                          setCustomisedSP({
                            ...customisedSP,
                            providerAvailability: {
                              ...customisedSP.providerAvailability,
                              config: {
                                ...customisedSP.providerAvailability.config,
                                maxAdvBookingDays: Number(e),
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomiseServiceProvider;
