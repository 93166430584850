import React, { useEffect, useState } from "react";
import FontIcon from "react-md/lib/FontIcons";
import "../styles/expandableTableAssignedPlans.css";
import EventService from "../../../../../service/event/EventService";
import moment from "moment";
import NoRecordsIcon from "../../../../../assets/icons/no-records.png";
import { Button, SelectField } from "react-md";
import filterApplied from "../../../../../assets/icons/filter-applied-icon.svg";
import funnelIcon from "../../../../../assets/icons/funnel.svg";
import {
  TextField,
  MenuItem,
  Menu,
  IconButton,
  Radio,
} from "@material-ui/core";

const ExpandableTableAssignedPlans = (props) => {
  const [expandedRowId, setExpandedRowId] = useState(null); // state to track expanded row
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuFor, setMenuFor] = useState({});
  const [tableData, setTableData] = useState(props?.data?.assignedPlans);
  const [filterObj, setFilterObj] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("props recvd in expandabel table: ", props);
  }, []);

  useEffect(() => {
    setTableData(props?.data?.assignedPlans);
    console.log("props recvd in expandabel table: ", props);
  }, [props]);

  useEffect(() => {
    props.fetchAssignedPlansByFilter(filterObj);
    console.log("props recvd in expandabel table: ", props);
  }, [filterObj]);

  const STATUS_MAP = {
    Active: 1,
    Inactive: 2,
    Upcoming: 3,
    Expired: 4,
  };

  const handleRowClick = (id) => {
    setExpandedRowId(expandedRowId === id ? null : id); // toggle expansion
  };
  const handleMoreClick = (event, planData) => {
    setAnchorEl(event.currentTarget);
    setMenuFor(planData);
  };

  const handleEditServiceProviders = (ele, opt) => {
    console.log("ni ,  In expanadable tile  -> ", ele);
    if (opt == 1) {
      props.setView(6, {
        mode: "view",
        service: props?.data?.service,
        serviceProvider: ele,
      });
    } else if (opt == 2) {
      props.setView(6, {
        mode: "update",
        service: props?.data?.service,
        serviceProvider: ele,
      });
    }
  };

  return (
    <div className="table-container-assigned-plans">
      <table className="expandable-table">
        <thead>
          <tr>
            <th>Plan ID</th>
            <th>Plan name</th>
            <th>Plan price</th>
            <th>Plan duration</th>
            <th>Plan start date</th>
            <th>Plan end date</th>
            <th>Wallet</th>
            <th>Reimbursement</th>
            <th>
              Status
              <img
                className="filter-icon-assigned-plans"
                src={filterObj?.status ? filterApplied : funnelIcon}
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(1);
                }}
              />
              {openFilter === 1 && (
                <div className="filter-div-assigned-plans-table">
                  <div className="can-be-booked-for-filter">
                    {Object.entries(STATUS_MAP).map(([key, val]) => (
                      <div>
                        <Radio
                          label={key}
                          checked={filterObj?.status == val}
                          onClick={() => {
                            setOpenBackdrop(false);
                            setOpenFilter(-1);
                            setFilterObj((prev) => ({ ...prev, status: val }));
                          }}
                        />
                        <label className="label-radio-btn">{key}</label>
                      </div>
                    ))}
                    <Button
                      className="clear-navigo-booked-for"
                      onClick={() => {
                        setFilterObj((prev) => ({ ...prev, status: null }));
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              )}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody className="animated-box">
          {Boolean(tableData?.length == null || tableData?.length <= 0) && (
            <tr>
              <td colSpan={9}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 40,
                  }}
                >
                  <div
                    style={{ textAlign: "center", fontFamily: "Montserrat" }}
                  >
                    No records to show
                  </div>
                  <div style={{ height: "16px" }}></div>
                  <img src={NoRecordsIcon} width={50} height={62} />
                </div>
              </td>
            </tr>
          )}

          {tableData && tableData.length > 0 && (
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td
                colSpan={7}
                align="right"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Button
                  onClick={() => {
                    props.goToPrevPage();
                  }}
                  disabled={props?.pageNum <= 1 || loading}
                >
                  <FontIcon className="navigo-count-icon">arrow_left</FontIcon>
                </Button>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  Showing&nbsp;
                  <span
                    style={{
                      color: "#ef7c01",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {(props?.pageNum - 1) * props?.pageSize + 1}&nbsp;
                  </span>
                  <span
                    style={{
                      color: "#ef7c01",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    - &nbsp;
                    {props?.totalCount > props?.pageNum * props?.pageSize
                      ? props?.pageNum * props?.pageSize
                      : props?.totalCount}
                  </span>
                  <span>&nbsp;&nbsp;of&nbsp;</span>
                  <span>{` ${props?.totalCount}`}</span>
                </div>
                <Button
                  onClick={() => {
                    props.goToNextPage();
                  }}
                  disabled={
                    loading ||
                    props?.pageNum * props?.pageSize > props?.totalCount ||
                    (props?.totalCount > props?.pageNum * props?.pageSize
                      ? props?.pageNum * props?.pageSize
                      : props?.totalCount) == props?.totalCount
                  }
                >
                  <FontIcon>arrow_right</FontIcon>
                </Button>
              </td>
            </tr>
          )}

          {tableData.map((row, index) => (
            <React.Fragment key={index}>
              <tr
                // onClick={() => handleRowClick(row?.serviceProviderId)}
                className={`${index > 0 ? "table-row-border" : ""}`}
              >
                <td>{row?.id}</td>
                <td>{row?.basicDetails?.name}</td>
                <td>{row?.basicDetails?.price}</td>
                <td>{row?.basicDetails?.duration} days</td>
                <td>
                  {moment(row?.basicDetails?.startDate).format("D MMM YYYY")}
                </td>
                <td>
                  {moment(row?.basicDetails?.endDate).format("D MMM YYYY")}
                </td>
                <td>
                  {row?.walletDetails?.isWalletIncluded
                    ? row?.walletDetails?.walletLimit
                    : "NA"}
                </td>
                <td>
                  {row?.walletDetails?.isWalletIncluded
                    ? row?.reimbursementDetails?.isReimbursementIncluded
                      ? "True"
                      : "False"
                    : "NA"}
                </td>
                <td>
                  {row?.basicDetails?.status != null && (
                    <div
                      className={`${
                        row?.basicDetails?.status
                          ? "navigo-appoint-non-select-status-active"
                          : "navigo-appoint-non-select-status-inactive"
                      } `}
                    >
                      <span>
                        {row?.basicDetails?.status == 1 ? "Active" : "Inactive"}
                      </span>
                    </div>
                  )}
                </td>
                <td>
                  <FontIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleMoreClick(e, row)}
                  >
                    more_vert
                  </FontIcon>
                </td>
              </tr>

              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                  setMenuFor(null);
                }}
              >
                <MenuItem
                  onClick={() => {
                    if (menuFor?.id) {
                      props.setView(3, {
                        mode: "update",
                        planId: menuFor?.id,
                      });
                    }
                  }}
                >
                  Edit plan
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (menuFor?.id) {
                      props.setView(3, {
                        mode: "view",
                        planId: menuFor?.id,
                      });
                    } else {
                      EventService.emit("showError", {
                        message: `Unable to get plan ID`,
                      });
                    }
                  }}
                >
                  View plan
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // props.setView(4, { planId: 13 })
                    if (menuFor?.id) {
                      props.setView(3, {
                        mode: "assign-service",
                        planId: menuFor?.id,
                      });
                    } else {
                      EventService.emit("showError", {
                        message: `Unable to get plan ID`,
                      });
                    }
                  }}
                >
                  Assigned services to plan
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // handleEditServiceProviders(menuFor, 1);
                    // setAnchorEl(null);
                  }}
                >
                  Whitelist users to plan
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // handleEditServiceProviders(menuFor, 1);
                    // setAnchorEl(null);
                  }}
                >
                  Whitelisted users
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // handleEditServiceProviders(menuFor, 1);
                    // setAnchorEl(null);
                  }}
                >
                  Renew plan
                </MenuItem>
              </Menu>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {openBackdrop && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
          }}
          onClick={() => {
            setOpenFilter(-1);
            setOpenBackdrop(false);
          }}
        ></div>
      )}
    </div>
  );
};

export default ExpandableTableAssignedPlans;
