import "../styles/index.css";
import { Grid, Row, Col } from "react-flexbox-grid";
import FontIcon from "react-md/lib/FontIcons";
import Card from "react-md/lib/Cards/Card";
import React from "react";
export const ServiceModuleHome = (props) => {
  return (
    <div className="service-dashboard-container">
      <Col
        xs={6}
        sm={5}
        md={4}
        lg={3}
        onClick={() => {
          props.setView(1);
        }}
      >
        <Card className="services-card">
          <FontIcon className="services-icon">local_pharmacy</FontIcon>
          <h4 className="services-icon-text-align-center">Service</h4>
        </Card>
      </Col>
      <Col
        xs={6}
        sm={5}
        md={4}
        lg={3}
        onClick={() => {
          props.setView(4);
        }}
      >
        <Card className="services-card">
          <FontIcon className="services-icon">local_pharmacy</FontIcon>
          <h4 className="services-icon-text-align-center">Service provider</h4>
        </Card>
      </Col>
    </div>
  );
};

export default ServiceModuleHome;
