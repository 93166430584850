import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";
import Helper from "util/Helper";

export class AssignedPlansUtil {
  constructor() {
    this.apiService = new APIService();
    this.helper = new Helper();
  }

  getAssignedPlansByFilter(body, onResponse, onError) {
    let url = APIConfig.myPlan.assignedPlans.getAssignedPlansByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }

  assignServiceToPlan(body, onResponse, onError) {
    let url = APIConfig.myPlan.assignedPlans.assignServiceToPlan;
    return this.apiService.post(url, body, onResponse, onError);
  }

  updateAssignedServiceToPlan(body, onResponse, onError) {
    let url = APIConfig.myPlan.assignedPlans.updateAssignedServiceToPlan;
    return this.apiService.put(url, body, onResponse, onError);
  }

  fetchAssignedServiceToPlan(planId, serviceId, onResponse, onError) {
    let url = APIConfig.myPlan.assignedPlans.fetchAssignedServiceToPlan.format(
      planId,
      serviceId
    );
    return this.apiService.get(url, onResponse, onError);
  }

  fetchAssignedServiceProvidersByFilter(body, onResponse, onError) {
    let url =
      APIConfig.myPlan.assignedPlans.fetchAssignedServiceProvidersByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }
}

export default AssignedPlansUtil;
