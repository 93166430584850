import { Checkbox, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import "../styles/availabilityCalendarStyle.css";
import { Button, SelectField, Card } from "react-md";
import FontIcon from "react-md/lib/FontIcons";
import filterApplied from "../../../../../assets/icons/filter-applied-icon.svg";
import funnelIcon from "../../../../../assets/icons/funnel.svg";
import eyeIcon from "../../../../../assets/icons/eye-orange.svg";
import editIcon from "../../../../../assets/icons/edit-orange.svg";
import CalendarAddOns from "./CalendarAddOns";
import moment from "moment";

const AvailabilityCalendar = ({
  availability, // availability must be provided (filled or empty) in the required format from the parent component
  setAvailability, // a function to change availability object of the parent component
  mode, // for view only set mode == 2
  headings, // array of heading required by weekly schedule, availability, unavailability and calendarAddOn dialog box
  showOnlySchedule, // when showOnlySchedule == true then UI for only weekly schedule will be rendered
  slotsValidationSchedule, //if availability.schedule is passed here, then Slots validation will get applied assuming availability.schedule to be the upper limit for slots
}) => {
  const [showAddOnDialog, setShowAddOnDialog] = useState(false);
  // addOnDialogMode = 1 for add button, 2 for view eye icon, 3 for edit icon
  const [addOnDialogMode, setAddOnDialogMode] = useState(0);
  const [dayData, setDayData] = useState(null);
  const [addOnindex, setAddOnindex] = useState(null);
  // type = 1 for unavailability (leaves) section and type = 2 for availability section
  const [type, setType] = useState(null);
  const [receivedSchedule, setReceivedSchedule] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openFilter, setOpenFilter] = useState(0);
  const [dateRangeFilter1ValueFrom, setDateRangeFilter1ValueFrom] =
    useState(null);
  const [dateRangeFilter1ValueTo, setDateRangeFilter1ValueTo] = useState(null);
  const [leavesFilterApplied, setLeavesFilterApplied] = useState(false);
  const [leaveDatesArr, setLeaveDatesArr] = useState([]);
  const [availabilityDatesArr, setAvailabilityDatesArr] = useState([]);
  const [dateRangeFilter2ValueFrom, setDateRangeFilter2ValueFrom] =
    useState(null);
  const [dateRangeFilter2ValueTo, setDateRangeFilter2ValueTo] = useState(null);
  const [availabilityFilterApplied, setAvailabilityFilterApplied] =
    useState(false);
  const [leavesPageNum, setLeavesPageNum] = useState(0);
  const [leavesTotalCount, setLeavesTotalCount] = useState(null);
  const [showPaginationForLeaves, setShowPaginationForLeaves] = useState(false);
  const [availabilityPageNum, setAvailabilityPageNum] = useState(0);
  const [availabilityTotalCount, setAvailabilityTotalCount] = useState(null);
  const [showPaginationForAvailability, setShowPaginationForAvailability] =
    useState(false);

  const PAGE_SIZE = 4;

  useEffect(() => {
    if (slotsValidationSchedule != undefined) {
      setReceivedSchedule(slotsValidationSchedule);
    }
    setDateRangeFilter1ValueFrom(moment().format("YYYY-MM-DD"));
    setDateRangeFilter1ValueTo(moment().add(1, "month").format("YYYY-MM-DD"));
    setDateRangeFilter2ValueFrom(moment().format("YYYY-MM-DD"));
    setDateRangeFilter2ValueTo(moment().add(1, "month").format("YYYY-MM-DD"));
    setLeaveDatesArr(availability.unavailableTime.map((row) => row.date));
    setAvailabilityDatesArr(availability.availableTime.map((row) => row.date));
  }, []);

  useEffect(() => {
    if (dateRangeFilter1ValueFrom != null && dateRangeFilter1ValueTo != null) {
      setLeavesFilterApplied(true);
      let dateFilteredLeaves = availability.unavailableTime.filter((row) =>
        moment(row.date).isBetween(
          moment(dateRangeFilter1ValueFrom),
          moment(dateRangeFilter1ValueTo),
          null,
          "[]"
        )
      );
      if (dateFilteredLeaves.length == 0) {
        setShowPaginationForLeaves(false);
      } else {
        setShowPaginationForLeaves(true);
      }
      setLeavesPageNum(0);
      setLeavesTotalCount(dateFilteredLeaves.length);
    } else {
      setLeavesFilterApplied(false);
      let dateFilteredLeaves = availability.unavailableTime;
      if (dateFilteredLeaves.length == 0) {
        setShowPaginationForLeaves(false);
      } else {
        setShowPaginationForLeaves(true);
      }
      setLeavesPageNum(0);
      setLeavesTotalCount(dateFilteredLeaves.length);
    }
  }, [dateRangeFilter1ValueFrom, dateRangeFilter1ValueTo, showAddOnDialog]);
  useEffect(() => {
    if (dateRangeFilter2ValueFrom != null && dateRangeFilter2ValueTo != null) {
      setAvailabilityFilterApplied(true);
      let dateFilteredAvailability = availability.availableTime.filter((row) =>
        moment(row.date).isBetween(
          moment(dateRangeFilter2ValueFrom),
          moment(dateRangeFilter2ValueTo),
          null,
          "[]"
        )
      );
      if (dateFilteredAvailability.length == 0) {
        setShowPaginationForAvailability(false);
      } else {
        setShowPaginationForAvailability(true);
      }
      setAvailabilityPageNum(0);
      setAvailabilityTotalCount(dateFilteredAvailability.length);
    } else {
      setAvailabilityFilterApplied(false);
      let dateFilteredAvailability = availability.availableTime;
      if (dateFilteredAvailability.length == 0) {
        setShowPaginationForAvailability(false);
      } else {
        setShowPaginationForAvailability(true);
      }
      setAvailabilityPageNum(0);
      setAvailabilityTotalCount(dateFilteredAvailability.length);
    }
  }, [dateRangeFilter2ValueFrom, dateRangeFilter2ValueTo, showAddOnDialog]);

  useEffect(() => {
    setLeaveDatesArr(availability.unavailableTime.map((row) => row.date));
    setAvailabilityDatesArr(availability.availableTime.map((row) => row.date));
    // setLeavesTotalCount((leavesTotalCount) => {
    //   return availability.unavailableTime.length;
    // });
  }, [showAddOnDialog]);

  const TIME_OBJECT_ITEMS = [
    { label: "12:00 AM", value: "00:00" },
    { label: "12:30 AM", value: "00:30" },
    { label: "01:00 AM", value: "01:00" },
    { label: "01:30 AM", value: "01:30" },
    { label: "02:00 AM", value: "02:00" },
    { label: "02:30 AM", value: "02:30" },
    { label: "03:00 AM", value: "03:00" },
    { label: "03:30 AM", value: "03:30" },
    { label: "04:00 AM", value: "04:00" },
    { label: "04:30 AM", value: "04:30" },
    { label: "05:00 AM", value: "05:00" },
    { label: "05:30 AM", value: "05:30" },
    { label: "06:00 AM", value: "06:00" },
    { label: "06:30 AM", value: "06:30" },
    { label: "07:00 AM", value: "07:00" },
    { label: "07:30 AM", value: "07:30" },
    { label: "08:00 AM", value: "08:00" },
    { label: "08:30 AM", value: "08:30" },
    { label: "09:00 AM", value: "09:00" },
    { label: "09:30 AM", value: "09:30" },
    { label: "10:00 AM", value: "10:00" },
    { label: "10:30 AM", value: "10:30" },
    { label: "11:00 AM", value: "11:00" },
    { label: "11:30 AM", value: "11:30" },
    { label: "12:00 PM", value: "12:00" },
    { label: "12:30 PM", value: "12:30" },
    { label: "01:00 PM", value: "13:00" },
    { label: "01:30 PM", value: "13:30" },
    { label: "02:00 PM", value: "14:00" },
    { label: "02:30 PM", value: "14:30" },
    { label: "03:00 PM", value: "15:00" },
    { label: "03:30 PM", value: "15:30" },
    { label: "04:00 PM", value: "16:00" },
    { label: "04:30 PM", value: "16:30" },
    { label: "05:00 PM", value: "17:00" },
    { label: "05:30 PM", value: "17:30" },
    { label: "06:00 PM", value: "18:00" },
    { label: "06:30 PM", value: "18:30" },
    { label: "07:00 PM", value: "19:00" },
    { label: "07:30 PM", value: "19:30" },
    { label: "08:00 PM", value: "20:00" },
    { label: "08:30 PM", value: "20:30" },
    { label: "09:00 PM", value: "21:00" },
    { label: "09:30 PM", value: "21:30" },
    { label: "10:00 PM", value: "22:00" },
    { label: "10:30 PM", value: "22:30" },
    { label: "11:00 PM", value: "23:00" },
    { label: "11:30 PM", value: "23:30" },
    { label: "11:59 PM", value: "23:59" },
  ];

  const getSlotOptions = (day) => {
    let slotsLimit = receivedSchedule[day];

    if (slotsValidationSchedule != undefined && Array.isArray(slotsLimit)) {
      return TIME_OBJECT_ITEMS.filter((timeItem) =>
        slotsLimit.some(
          (slot) => timeItem.value >= slot.start && timeItem.value <= slot.end
        )
      );
    }

    return TIME_OBJECT_ITEMS;
  };
  const getContiguousSlotOptions = (day, slot) => {
    let availableSlots = getSlotOptions(day).filter(
      (item) => item.value > slot.start
    );
    const startIndex = TIME_OBJECT_ITEMS.findIndex(
      (item) => item.value === slot.start
    );

    if (startIndex === -1) return [];
    let contiguousSlots = [];
    for (let i = startIndex + 1; i < TIME_OBJECT_ITEMS.length; i++) {
      if (
        availableSlots.some((item) => item.value === TIME_OBJECT_ITEMS[i].value)
      ) {
        contiguousSlots.push(TIME_OBJECT_ITEMS[i]);
      } else {
        break;
      }
    }
    return contiguousSlots;
  };

  const getTimeLabel = (value) => {
    const timeItem = TIME_OBJECT_ITEMS.find((item) => item.value === value);
    return timeItem ? timeItem.label : "Invalid Time";
  };

  let renderSlotsForDay = (day, slots) => {
    return (
      <>
        {slots.length == 0 ? (
          <div className="time">
            <div className="time-container">
              <div className="time-text">Start time</div>
              <SelectField
                id="time-select-field"
                placeholder="Select"
                className="select-time"
                disabled
              />
            </div>
            <div className="time-container">
              <div className="time-text">End time</div>
              <SelectField
                id="time-select-field"
                placeholder="Select"
                className="select-time"
                disabled
              />
            </div>
          </div>
        ) : (
          <>
            {slots.map((slot, index) => {
              return (
                <div className="time">
                  <div className="time-container">
                    <div className="time-text">Start time</div>
                    <SelectField
                      id="time-select-field"
                      placeholder="Select"
                      className="select-time"
                      value={slot.start}
                      menuItems={
                        index == 0
                          ? getSlotOptions(day).filter(
                              (item) => item.value != "23:59"
                            )
                          : getSlotOptions(day).filter((item) => {
                              if (
                                item.value > slots[index - 1].end &&
                                item.value != "23:59"
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            })
                      }
                      onChange={(e) => {
                        let newSchedule = [...availability.schedule[day]];
                        newSchedule[index] = {
                          ...newSchedule[index],
                          start: e,
                          end: "",
                        };
                        newSchedule = newSchedule.slice(0, index + 1);
                        let obj = {
                          ...availability,
                          schedule: {
                            ...availability.schedule,
                            [day]: newSchedule,
                          },
                        };
                        setAvailability(obj);
                      }}
                      disabled={mode == 2}
                    />
                  </div>
                  <div className="time-container">
                    <div className="time-text">End time</div>
                    <SelectField
                      id="time-select-field"
                      placeholder="Select"
                      className="select-time"
                      value={slot.end}
                      // menuItems={TIME_OBJECT_ITEMS}
                      // menuItems={getSlotOptions(day).filter(
                      //   (item) => item.value > slot.start
                      // )}
                      menuItems={getContiguousSlotOptions(day, slot)}
                      onChange={(e) => {
                        let newSchedule = [...availability.schedule[day]];
                        newSchedule[index] = {
                          ...newSchedule[index],
                          end: e,
                        };
                        newSchedule = newSchedule.slice(0, index + 1);
                        let obj = {
                          ...availability,
                          schedule: {
                            ...availability.schedule,
                            [day]: newSchedule,
                          },
                        };
                        setAvailability(obj);
                      }}
                      disabled={mode == 2 || slot.start == ""}
                    />
                  </div>
                  {index == 0 &&
                    slotsValidationSchedule == undefined &&
                    Object.keys(availability.schedule)[0] == day &&
                    slots.at(-1).end != "" && (
                      <div
                        className="remove-slot"
                        onClick={() => {
                          let newSchedule = [...availability.schedule[day]];
                          let updatedSchedule = Object.keys(
                            availability.schedule
                          ).reduce((acc, key) => {
                            acc[key] = newSchedule;
                            return acc;
                          }, {});

                          let obj = {
                            ...availability,
                            schedule: updatedSchedule,
                          };
                          setAvailability(obj);
                        }}
                      >
                        <FontIcon>content_copy</FontIcon> Copy to all
                      </div>
                    )}
                  {index > 0 ? (
                    mode == 2 ? (
                      <></>
                    ) : (
                      <div
                        className="remove-slot"
                        onClick={() => {
                          let newSchedule = [...availability.schedule[day]];
                          newSchedule.splice(index, 1);

                          let obj = {
                            ...availability,
                            schedule: {
                              ...availability.schedule,
                              [day]: newSchedule,
                            },
                          };
                          setAvailability(obj);
                        }}
                      >
                        <FontIcon>close</FontIcon>
                      </div>
                    )
                  ) : (
                    <div style={{ width: "55px" }}></div>
                  )}
                </div>
              );
            })}
          </>
        )}
      </>
    );
  };

  let renderLeaves = (leave, index, typeNo) => {
    return (
      <div className="leave-row">
        <Row>
          <Col xs={1} sm={1} md={1} lg={1}></Col>
          <Col xs={2} sm={2} md={2} lg={2}>
            {moment(leave.date).format("DD MMM YYYY")}
          </Col>
          <Col xs={1} sm={1} md={1} lg={1}></Col>
          <Col xs={3} sm={3} md={3} lg={3}>
            {leave.slots[0].start == "00:00" && leave.slots[0].end == "23:59"
              ? `Full day`
              : `${getTimeLabel(leave.slots[0].start)} - ${getTimeLabel(
                  leave.slots[0].end
                )}${leave.slots.length > 1 ? " and more" : ""}`}
          </Col>
          <Col xs={4} sm={4} md={4} lg={4}></Col>
          <Col xs={1} sm={1} md={1} lg={1}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div
                onClick={() => {
                  setType(typeNo);
                  setAddOnDialogMode(2);
                  setShowAddOnDialog(true);
                  setDayData(leave);
                }}
              >
                <FontIcon style={{ color: "#F77F0F" }}>visibility</FontIcon>
              </div>
              {mode == 2 ? (
                <></>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setType(typeNo);
                    setAddOnindex(index);
                    setAddOnDialogMode(3);
                    setShowAddOnDialog(true);
                    setDayData(leave);
                  }}
                >
                  <img src={editIcon} alt="view" height={"18px"} />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  let openCalendarAddOns = () => {
    setShowAddOnDialog(true);
    setAddOnDialogMode(1);
  };
  let closeCalendarAddOns = () => {
    setShowAddOnDialog(false);
    setAddOnDialogMode(0);
    setAddOnindex(null);
    setDayData(null);
    setType(null);
  };

  let filterLeavesToDisplay = (leaves) => {
    if (leavesFilterApplied) {
      let dateFilteredLeaves = leaves.filter((row) =>
        moment(row.date).isBetween(
          moment(dateRangeFilter1ValueFrom),
          moment(dateRangeFilter1ValueTo),
          null,
          "[]"
        )
      );

      return dateFilteredLeaves.filter(
        (e, i) =>
          i + 1 >= leavesPageNum * PAGE_SIZE + 1 &&
          i + 1 <=
            (leavesTotalCount < (leavesPageNum + 1) * PAGE_SIZE
              ? leavesTotalCount
              : (leavesPageNum + 1) * PAGE_SIZE)
      );
    } else {
      return leaves.filter(
        (e, i) =>
          i + 1 >= leavesPageNum * PAGE_SIZE + 1 &&
          i + 1 <=
            (leavesTotalCount < (leavesPageNum + 1) * PAGE_SIZE
              ? leavesTotalCount
              : (leavesPageNum + 1) * PAGE_SIZE)
      );
    }
  };
  let filterAvailabilityToDisplay = (availability) => {
    if (availabilityFilterApplied) {
      let dateFilteredAvailability = availability.filter((row) =>
        moment(row.date).isBetween(
          moment(dateRangeFilter2ValueFrom),
          moment(dateRangeFilter2ValueTo),
          null,
          "[]"
        )
      );

      return dateFilteredAvailability.filter(
        (e, i) =>
          i + 1 >= availabilityPageNum * PAGE_SIZE + 1 &&
          i + 1 <=
            (availabilityTotalCount < (availabilityPageNum + 1) * PAGE_SIZE
              ? availabilityTotalCount
              : (availabilityPageNum + 1) * PAGE_SIZE)
      );
    } else {
      return availability.filter(
        (e, i) =>
          i + 1 >= availabilityPageNum * PAGE_SIZE + 1 &&
          i + 1 <=
            (availabilityTotalCount < (availabilityPageNum + 1) * PAGE_SIZE
              ? availabilityTotalCount
              : (availabilityPageNum + 1) * PAGE_SIZE)
      );
    }
  };

  return (
    <>
      {console.log(
        "pr- date1=",
        dateRangeFilter1ValueFrom,
        dateRangeFilter1ValueTo,
        openBackdrop,
        openFilter
      )}
      <div className="mpc-container">
        <h3>{headings[0]}</h3>
        <div className="weekly-schedule">
          {Object.keys(availability.schedule).map((day, index) => (
            <div className="day">
              <div key={index} className="day-checkbox">
                <Checkbox
                  id={`mpc-checkbox-${day}`}
                  name={`mpc-checkbox-${day}`}
                  color={mode == 2 ? "default" : "secondary"}
                  disabled={mode == 2}
                  checked={
                    availability.schedule[day].length == 0 ? false : true
                  }
                  onChange={(event) => {
                    if (availability.schedule[day].length == 0) {
                      setAvailability({
                        ...availability,
                        schedule: {
                          ...availability.schedule,
                          [day]: [
                            {
                              start: "",
                              end: "",
                            },
                          ],
                        },
                      });
                    } else {
                      setAvailability({
                        ...availability,
                        schedule: {
                          ...availability.schedule,
                          [day]: [],
                        },
                      });
                    }
                  }}
                />
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </div>
              <div className="day-timings">
                <div className="rows">
                  {renderSlotsForDay(day, availability.schedule[day])}
                </div>
                {availability.schedule[day].length == 0 ? (
                  <></>
                ) : mode == 2 ? (
                  <></>
                ) : availability.schedule[day].every(
                    (slot) =>
                      slot.start !== "" &&
                      slot.end !== "" &&
                      slot.end !== "23:30" &&
                      slot.end !== "23:59"
                  ) ? (
                  <div
                    className="add-more"
                    onClick={() => {
                      setAvailability({
                        ...availability,
                        schedule: {
                          ...availability.schedule,
                          [day]: [
                            ...availability.schedule[day],
                            {
                              start: "",
                              end: "",
                            },
                          ],
                        },
                      });
                    }}
                  >{`+ Add more`}</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
        </div>
        {showOnlySchedule ? (
          <></>
        ) : (
          <>
            <br />
            <br />
            <div className="heading-and-add-btn">
              <h3>{headings[2]}</h3>
              <div>
                <Button
                  raised
                  disabled={mode == 2}
                  className={mode == 2 ? "btn-disabled" : "btn-enabled"}
                  onClick={() => {
                    setType(2);
                    openCalendarAddOns();
                  }}
                >
                  {"Add"}
                </Button>
              </div>
            </div>
            <div>
              <Card>
                <Row>
                  <Col xs={1} sm={1} md={1} lg={1}></Col>
                  <Col xs={2} sm={2} md={2} lg={2}>
                    <div style={{ paddingTop: "5px", paddingBottom: "6px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="column-heading-text">Date</div>
                        <div
                          style={{ marginLeft: "38px", position: "absolute" }}
                        >
                          <img
                            src={
                              availabilityFilterApplied
                                ? filterApplied
                                : funnelIcon
                            }
                            onClick={() => {
                              console.log("pr- funel clicked 2");

                              setOpenBackdrop(true);
                              setOpenFilter(2);
                            }}
                          />
                          {openFilter === 2 && (
                            <div className="calendar-filter-popup">
                              <div className="calendar-filter-popup-date-range-filter">
                                <p>From</p>
                                <TextField
                                  type="date"
                                  style={{ width: "90%" }}
                                  className="dob"
                                  id="dob"
                                  onChange={(v) => {
                                    if (v.target.value) {
                                      setDateRangeFilter2ValueFrom(
                                        v.target.value
                                      );
                                    }
                                  }}
                                  value={dateRangeFilter2ValueFrom}
                                ></TextField>
                              </div>
                              <div className="calendar-filter-popup-date-range-filter">
                                <p>To</p>
                                <TextField
                                  type="date"
                                  defaultValue={""}
                                  style={{ width: "90%" }}
                                  className="dob"
                                  id="dob"
                                  onChange={(v) => {
                                    if (v.target.value) {
                                      setDateRangeFilter2ValueTo(
                                        v.target.value
                                      );
                                    }
                                  }}
                                  value={dateRangeFilter2ValueTo}
                                ></TextField>
                              </div>

                              <Button
                                className=""
                                onClick={() => {
                                  setDateRangeFilter2ValueFrom(null);
                                  setDateRangeFilter2ValueTo(null);
                                  setAvailabilityFilterApplied(false);
                                  setOpenFilter(0);
                                  setOpenBackdrop(false);
                                }}
                              >
                                <FontIcon className="">close</FontIcon>
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="column-heading-filter-text">
                        {availabilityFilterApplied
                          ? `${moment(dateRangeFilter2ValueFrom).format(
                              "DD MMM YYYY"
                            )} - ${moment(dateRangeFilter2ValueTo).format(
                              "DD MMM YYYY"
                            )} `
                          : ` Showing all`}
                      </div>
                    </div>
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}></Col>
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <div
                      className="column-heading-text"
                      style={{ paddingTop: "5px", paddingBottom: "6px" }}
                    >
                      Availability
                    </div>
                  </Col>
                </Row>
              </Card>
              {showPaginationForAvailability && (
                <div className="pagination-container">
                  <Button
                    className="page-button-left"
                    onClick={() => {
                      setAvailabilityPageNum(
                        (availabilityPageNum) => availabilityPageNum - 1
                      );
                    }}
                    disabled={availabilityPageNum == 0}
                  >
                    <FontIcon
                      className={
                        availabilityPageNum == 0
                          ? "icon-disable"
                          : "icon-enable"
                      }
                    >
                      arrow_drop_up
                    </FontIcon>
                  </Button>
                  <div>{`Showing`}&nbsp;</div>
                  <div style={{ color: "#ef7c01" }}>{`${
                    availabilityPageNum * PAGE_SIZE + 1
                  } - ${
                    availabilityTotalCount <
                    (availabilityPageNum + 1) * PAGE_SIZE
                      ? availabilityTotalCount
                      : (availabilityPageNum + 1) * PAGE_SIZE
                  }`}</div>
                  <div>&nbsp;{`of ${availabilityTotalCount}`}</div>
                  <Button
                    className="page-button-right"
                    onClick={() => {
                      setAvailabilityPageNum(
                        (availabilityPageNum) => availabilityPageNum + 1
                      );
                    }}
                    disabled={
                      availabilityTotalCount <=
                      (availabilityPageNum + 1) * PAGE_SIZE
                    }
                  >
                    <FontIcon
                      className={
                        availabilityTotalCount <=
                        (availabilityPageNum + 1) * PAGE_SIZE
                          ? "icon-disable"
                          : "icon-enable"
                      }
                    >
                      arrow_drop_up
                    </FontIcon>
                  </Button>
                </div>
              )}
              {filterAvailabilityToDisplay(availability.availableTime).map(
                (row, index) => {
                  return renderLeaves(row, index, 2);
                }
              )}
            </div>

            <br />
            {/* ------------------- */}
            <div className="heading-and-add-btn">
              <h3>{headings[1]}</h3>
              <div>
                <Button
                  raised
                  disabled={mode == 2}
                  className={mode == 2 ? "btn-disabled" : "btn-enabled"}
                  onClick={() => {
                    setType(1);
                    openCalendarAddOns();
                  }}
                >
                  {"Add"}
                </Button>
              </div>
            </div>
            <div>
              <Card>
                <Row>
                  <Col xs={1} sm={1} md={1} lg={1}></Col>
                  <Col xs={2} sm={2} md={2} lg={2}>
                    <div style={{ paddingTop: "5px", paddingBottom: "6px" }}>
                      <div style={{ display: "flex" }}>
                        <div className="column-heading-text">Date</div>
                        <div
                          style={{ marginLeft: "38px", position: "absolute" }}
                        >
                          <img
                            src={
                              leavesFilterApplied ? filterApplied : funnelIcon
                            }
                            onClick={() => {
                              console.log("pr- funel clicked");

                              setOpenBackdrop(true);
                              setOpenFilter(1);
                            }}
                          />
                          {openFilter === 1 && (
                            <div className="calendar-filter-popup">
                              <div className="calendar-filter-popup-date-range-filter">
                                <p>From</p>
                                <TextField
                                  type="date"
                                  style={{ width: "90%" }}
                                  className="dob"
                                  id="dob"
                                  onChange={(v) => {
                                    if (v.target.value) {
                                      setDateRangeFilter1ValueFrom(
                                        v.target.value
                                      );
                                    }
                                  }}
                                  value={dateRangeFilter1ValueFrom}
                                ></TextField>
                              </div>
                              <div className="calendar-filter-popup-date-range-filter">
                                <p>To</p>
                                <TextField
                                  type="date"
                                  defaultValue={""}
                                  style={{ width: "90%" }}
                                  className="dob"
                                  id="dob"
                                  onChange={(v) => {
                                    if (v.target.value) {
                                      setDateRangeFilter1ValueTo(
                                        v.target.value
                                      );
                                    }
                                  }}
                                  value={dateRangeFilter1ValueTo}
                                ></TextField>
                              </div>

                              <Button
                                className=""
                                onClick={() => {
                                  setDateRangeFilter1ValueFrom(null);
                                  setDateRangeFilter1ValueTo(null);
                                  setLeavesFilterApplied(false);
                                  setOpenFilter(0);
                                  setOpenBackdrop(false);
                                }}
                              >
                                <FontIcon className="">close</FontIcon>
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="column-heading-filter-text">
                        {leavesFilterApplied
                          ? `${moment(dateRangeFilter1ValueFrom).format(
                              "DD MMM YYYY"
                            )} - ${moment(dateRangeFilter1ValueTo).format(
                              "DD MMM YYYY"
                            )} `
                          : ` Showing all`}
                      </div>
                    </div>
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}></Col>
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <div
                      className="column-heading-text"
                      style={{ paddingTop: "5px", paddingBottom: "6px" }}
                    >
                      Unavailability
                    </div>
                  </Col>
                </Row>
              </Card>
              {showPaginationForLeaves && (
                <div className="pagination-container">
                  <Button
                    className="page-button-left"
                    onClick={() => {
                      setLeavesPageNum((leavesPageNum) => leavesPageNum - 1);
                    }}
                    disabled={leavesPageNum == 0}
                  >
                    <FontIcon
                      className={
                        leavesPageNum == 0 ? "icon-disable" : "icon-enable"
                      }
                    >
                      arrow_drop_up
                    </FontIcon>
                  </Button>
                  <div>{`Showing`}&nbsp;</div>
                  <div style={{ color: "#ef7c01" }}>{`${
                    leavesPageNum * PAGE_SIZE + 1
                  } - ${
                    leavesTotalCount < (leavesPageNum + 1) * PAGE_SIZE
                      ? leavesTotalCount
                      : (leavesPageNum + 1) * PAGE_SIZE
                  }`}</div>
                  <div>&nbsp;{`of ${leavesTotalCount}`}</div>
                  <Button
                    className="page-button-right"
                    onClick={() => {
                      setLeavesPageNum((leavesPageNum) => leavesPageNum + 1);
                    }}
                    disabled={
                      leavesTotalCount <= (leavesPageNum + 1) * PAGE_SIZE
                    }
                  >
                    <FontIcon
                      className={
                        leavesTotalCount <= (leavesPageNum + 1) * PAGE_SIZE
                          ? "icon-disable"
                          : "icon-enable"
                      }
                    >
                      arrow_drop_up
                    </FontIcon>
                  </Button>
                </div>
              )}

              {filterLeavesToDisplay(availability.unavailableTime).map(
                (row, index) => {
                  return renderLeaves(row, index, 1);
                }
              )}
            </div>
          </>
        )}

        {showAddOnDialog && (
          <CalendarAddOns
            closeCalendarAddOns={closeCalendarAddOns}
            addOnDialogMode={addOnDialogMode}
            // setAddOns={setAddOns}
            availability={availability}
            setAvailability={setAvailability}
            type={type}
            heading={
              addOnDialogMode == 1
                ? `Add ${type == 1 ? `un${headings[3]}` : headings[3]}`
                : addOnDialogMode == 2
                ? `View ${type == 1 ? `un${headings[3]}` : headings[3]}`
                : addOnDialogMode == 3
                ? `Edit ${type == 1 ? `un${headings[3]}` : headings[3]}`
                : ""
            }
            dayData={dayData}
            addOnindex={addOnindex}
            leaveDatesArr={
              dayData == null
                ? leaveDatesArr
                : leaveDatesArr.filter((date) => date !== dayData.date)
            }
            availabilityDatesArr={
              dayData == null
                ? availabilityDatesArr
                : availabilityDatesArr.filter((date) => date !== dayData.date)
            }
          />
        )}
        {openBackdrop && (
          <div
            className="calendar-backdrop-class"
            onClick={() => {
              setOpenFilter(0);
              setOpenBackdrop(false);
            }}
          ></div>
        )}
      </div>
    </>
  );
};

export default AvailabilityCalendar;
