import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
const useStyles = makeStyles({
  root: {
    width: 200,
  },
});
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#EF7C03",
    color: "#fff",
    fontSize: 12,
    fontWeight: "bold",
    transform: "translateY(-5px)",
    padding: "4px 8px",
  },
  arrow: {
    color: "#EF7C03",
  },
})(Tooltip);
function ValueLabelComponent(props) {
  const { children, open, value } = props;
  return (
    <CustomTooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      arrow
    >
      {children}
    </CustomTooltip>
  );
}
ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};
const CustomSlider = withStyles({
  root: {
    color: "#EF7C03",
  },
  thumb: {
    "&:focus, &:hover, &$active": {
      boxShadow: "none !important",
    },
  },
})(Slider);
export default function RangeSlider(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState([props.min, props.max]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.setAge(newValue[0], newValue[1]);
  };
  return (
    <div className={classes.root}>
      <CustomSlider
        value={value}
        onChange={handleChange}
        ValueLabelComponent={ValueLabelComponent}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        min={props.rangeMin}
        max={props.rangeMax}
      />
    </div>
  );
}
