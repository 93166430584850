import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";
import Helper from "util/Helper";

export class CreateServiceUtil {
  constructor() {
    this.apiService = new APIService();
    this.helper = new Helper();
  }

  getServiceType(body, onResponse, onError) {
    let url = APIConfig.myPlan.createService.getServiceType.format(body);
    return this.apiService.get(url, onResponse, onError);
  }
  getServiceSubType(id, status, onResponse, onError) {
    let url = APIConfig.myPlan.createService.getServiceSubType.format(
      id,
      status
    );

    return this.apiService.get(url, onResponse, onError);
  }
  //upload  image
  uploadImg = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.uploadContent;
    return this.apiService.postWithFormData(url, body, onResponse, onError);
  };
  createService(body, onResponse, onError) {
    let url = APIConfig.myPlan.createService.createNewService;
    return this.apiService.post(url, body, onResponse, onError);
  }
  updateService(id, body, onResponse, onError) {
    let url = APIConfig.myPlan.createService.updateService.format(id);
    return this.apiService.put(url, body, onResponse, onError);
  }
  getServiceById(id, onResponse, onError) {
    let url = APIConfig.myPlan.createService.getServiceById.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  getServiceProviderById(id, onResponse, onError) {
    let url = APIConfig.myPlan.serviceProvider.getById.format(id);
    return this.apiService.get(url, onResponse, onError);
  }

  // getDoseSchedule(data, onResponse, onError) {
  //   let url = `${APIConfig.doseScheduleApis.getDoseSchedule}?pid=${data}`;
  //   return this.apiService.get(url, onResponse, onError);
  // }

  // updateDoseSchedule(body, onResponse, onError) {
  //   let url = APIConfig.doseScheduleApis.updateDoseSchedule;
  //   return this.apiService.put(url, body, onResponse, onError);
  // }
  // createProgressNote(body, onResponse, onError) {
  //   let url = APIConfig.progressNote.new;
  //   return this.apiService.post(url, body, onResponse, onError);
  // }
}

export default CreateServiceUtil;
