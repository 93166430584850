import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";
import Helper from "util/Helper";

export class AssignServiceProviderUtil {
  constructor() {
    this.apiService = new APIService();
    this.helper = new Helper();
  }

  getServiceProviderById(id, onResponse, onError) {
    let url =
      APIConfig.myPlan.assignServiceProvider.getServiceProvidersById.format(id);
    return this.apiService.get(url, onResponse, onError);
  }

  getServiceProviderByFilter(body, onResponse, onError) {
    let url = APIConfig.myPlan.assignServiceProvider.getServiceProviderByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }

  getServiceTypeById(id, onResponse, onError) {
    let url =
      APIConfig.myPlan.assignServiceProvider.getServiceTypeById.format(id);
    return this.apiService.get(url, onResponse, onError);
  }

  assignServiceProvider(body, onResponse, onError) {
    let url = APIConfig.myPlan.assignServiceProvider.assignServiceProvider;
    return this.apiService.post(url, body, onResponse, onError);
  }

  assignServiceProviderByGroupVendorId(body, onResponse, onError) {
    let url =
      APIConfig.myPlan.assignServiceProvider
        .assignServiceProviderByGroupVendorId;
    return this.apiService.post(url, body, onResponse, onError);
  }

  updateAssignedServiceProvider(id, body, onResponse, onError) {
    let url =
      APIConfig.myPlan.assignServiceProvider.updateAssignedServiceProvider.format(
        id
      );
    return this.apiService.put(url, body, onResponse, onError);
  }

  getAssignedServiceProvidersByFilter(body, onResponse, onError) {
    let url =
      APIConfig.myPlan.assignServiceProvider
        .getAssignedServiceProvidersByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }

  getAllGroupVendors(status, onResponse, onError) {
    let url =
      APIConfig.myPlan.assignServiceProvider.getAllGroupVendors.format(status);
    return this.apiService.get(url, onResponse, onError);
  }

  getAssignedServiceProviderForService(
    serviceId,
    serviceProviderId,
    onResponse,
    onError
  ) {
    let url =
      APIConfig.myPlan.assignServiceProvider.getAssignedServiceProviderForService.format(
        serviceId,
        serviceProviderId
      );
    return this.apiService.get(url, onResponse, onError);
  }
}

export default AssignServiceProviderUtil;
